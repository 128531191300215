import {
  ApolloClient,
  createHttpLink,
  gql,
  NormalizedCacheObject,
  ApolloLink,
  from,
  HttpLink,
} from "@apollo/client";
import { BACKEND_URL } from "../utils/variables";
import { cache } from "./cache";
import Axios from "axios";

import { createUploadLink } from "apollo-upload-client";

const restAPI = Axios.create({
  baseURL: BACKEND_URL,
  headers: {
    authorization: localStorage.getItem("token") || "",
  },
});

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      ...Headers,
      authorization: localStorage.getItem("token") || null,
    },
  });

  return forward(operation);
});

const httpLink = new createUploadLink({
  uri: BACKEND_URL,
});

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]) as any,

  cache,
});

export { client, typeDefs, restAPI };
