import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { EDIT_ONE_FOLDER } from "../../../apollo/mutations";
import {
  GET_FOLDER,
  GET_FOLDER_SESSION_OPTIONS,
} from "../../../apollo/queries";
import { fetchFolderSessionOptions } from "../../../apollo/queries/__generated__/fetchFolderSessionOptions";
import { getFolder } from "../../../apollo/queries/__generated__/getFolder";
import { theme } from "../../../utils/theme";
import { Button, Loading } from "../../UI";
import { Modal } from "../../UI/AppModal";
import { AddOrEditSession } from "./AddOrEditSession";

export const EditFolder = (props: any) => {
  const folderId = Number(props.match.params.folderId);
  const [modal, showModal] = useState<boolean>(false);

  const { data, loading, error } = useQuery<getFolder>(GET_FOLDER, {
    variables: {
      data: {
        id: folderId,
      },
      fetchPolicy: "network-only",
    },
  });

  const {
    loading: sessionLoading,
    error: sessionError,
    data: fetchFolderSessionOptions,
  } = useQuery<fetchFolderSessionOptions>(GET_FOLDER_SESSION_OPTIONS, {
    fetchPolicy: "network-only",
    variables: {
      folder_id: folderId,
    },
  });

  const [addOrEditMutation, { loading: addOrEditMutationLoading }] =
    useMutation(EDIT_ONE_FOLDER, {});

  const [filterState, setFilterState] = useState({
    parent_label: [],
    is_active: [],
    is_premium: [],
  });

  if (loading && sessionLoading) {
    return <Loading />;
  }

  const folder = data?.folder;
  const sessionOptions = fetchFolderSessionOptions?.fetchFolderSessionOptions;

  if (!folder || !sessionOptions) {
    return <Loading />;
  }

  return (
    <Container>
      <h1>{folder.name}</h1>

      <AddOrEditSession
        sessionData={folder.sessions}
        sessionOptions={sessionOptions}
        folderId={folderId}
        closeModal={() => {}}
      />
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  max-width: 1200px;
  width: 95%;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
`;
