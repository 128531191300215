import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ADD_ONE_PREMIUM_USER } from "../../../apollo/mutations";
import { GET_ALL_PREMIUM_USERS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { FileUpload } from "../../Segments/FileUpload";
import { Button, Loading, SuccessMessage, SwalError } from "../../UI";
import { PremiumUser } from "./PremiumUser";

export const PremiumUsers = () => {
  let history = useHistory();

  const { loading, error, data } = useQuery(GET_ALL_PREMIUM_USERS, {});
  const [addOnePremiumUser, { error: AddError, data: AddData }] = useMutation(
    ADD_ONE_PREMIUM_USER,
    {
      onCompleted({ addOnePremiumUser }) {
        SuccessMessage();
        setEmail("");
        history.push("/premium");
      },
      onError({ message }) {
        SwalError(message);
        history.push("/premium");
      },
      refetchQueries: [{ query: GET_ALL_PREMIUM_USERS }],
      awaitRefetchQueries: true,
    }
  );

  const [email, setEmail] = useState("");

  if (loading) return <Loading />;
  if (error || AddError) return <Loading />;

  const premium_users = data.premiumUsers;

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddEvent = () => {
    addOnePremiumUser({
      variables: {
        email: email,
      },
    });
  };
  const users = premium_users.map((premium_user, i) => {
    return <PremiumUser premium_user={premium_user} key={i} />;
  });

  return (
    <Container>
      <InlineLabel>
        <p>Add Premium User:</p>
        <FileUpload fileType="csv" />

        <InputForm type="text" onChange={handleChange} value={email} />
        <Button
          height={38}
          color={"#fff"}
          type="button"
          onClick={handleAddEvent}
        >
          Submit
        </Button>
      </InlineLabel>
      <UsersContainer>{users}</UsersContainer>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  width: 95%;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
`;
const LinkEnd = styled(Link)`
  align-self: flex-end;
`;

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin: 5px 0;
  }
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin: 20px auto;
  p {
    font-size: 14px;
    margin: 0 0 7px;
    color: #152935;
  }
`;

const InputForm = styled.input`
  width: ${(props) => props.width || 500}px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;
