import { useMutation, useQuery } from "@apollo/client";
import Select from "react-select";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GET_ALL_QUESTIONS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { Button, BUTTON_END, DELETE_BUTTON, Loading } from "../../UI";
import { SingleSelect } from "../../UI/Selector";

export const CustomAnswer = (props) => {
  const { answer, handleChange, index, handleDelete } = props;

  useEffect(() => {}, [answer]);

  const handleAnswerChange = (e) => {
    console.log(e);

    const value = e.target ? e.target.value : e.value;
    const name = e.target ? e.target.name : e.name;

    handleChange(value, name, index);
  };

  return (
    <FlexRow>
      <InputForm
        type="text"
        width={300}
        placeholder="answer"
        name="answer"
        value={answer.answer}
        onChange={handleAnswerChange}
      />
      <Selector
        className="selector"
        name="is_premium"
        class
        value={{
          value: answer.is_premium,
          label: answer.is_premium ? "Premium" : "Free",
          name: "is_premium",
        }}
        onChange={handleAnswerChange}
        options={[
          { value: true, label: "Premium", name: "is_premium" },
          {
            value: false,
            label: "Free",
            name: "is_premium",
          },
        ]}
      />
      <Button
        backgroundColor={"#d0021b"}
        color={"#fff"}
        width={100}
        type="button"
        onClick={(e) => handleDelete(index)}
      >
        Delete Answer
      </Button>
    </FlexRow>
  );
};

const InputForm = styled.input`
  width: ${(props) => props.width || 500}px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin-left: 26px;
  }
`;

const Selector = styled(Select).attrs({ activeClassName: "selector" })`
  width: 200px;
`;
