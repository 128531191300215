import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../utils/theme";

export const Background = styled.section`
  min-height: calc(100vh - 50px);
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  justify-content: center;
`;
