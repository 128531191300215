import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GET_ALL_QUESTIONS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { BUTTON_END, Loading } from "../../UI";
import { CustomQuestion } from "./CustomQuestion";

export const CustomManager = () => {
  const { data, loading, error } = useQuery(GET_ALL_QUESTIONS);
  // let history = useHistory();

  if (loading) {
    return <Loading />;
  }

  const questions = data.fetchAllQuestions;

  const question_form = questions.map((question, i) => {
    return <CustomQuestion question={question} key={i} />;
  });
  return <Container>{question_form}</Container>;
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
`;

const LinkEnd = styled(Link)`
  align-self: flex-end;
`;

export const Table = styled.table`
  padding: 25px 15px;
  margin-top: 25px;
  align-self: center;
  text-align: center;
  display: grid;
  border-radius: 5px;
  background-color: #fff;
  border-collapse: unset;
  overflow: auto;
  grid-template-columns: repeat(5, 300px);
`;

const Row = styled.tr`
  display: contents;
  padding: 15px;
`;

const Col = styled.td`
  border-bottom: solid 1.5px #e7e7e7;
  padding: 15px 0;
`;
