import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IS_LOGGED_IN } from "../apollo/queries/index";
import { useQuery } from "@apollo/client";
import { Loading } from "../Components/UI";
import { isLoggedInVar } from "../apollo/cache";

interface PublicRouteProps {
  component: any;
  path?: string;
  exact?: boolean;
}

type Props = PublicRouteProps;

export const PublicRouteComponent = ({
  component: Component,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  const { data, loading } = useQuery(IS_LOGGED_IN);
  console.log("public data: ", data);

  if (loading) {
    return <Loading />;
  }

  const is_authenticated = data.isLoggedIn;

  return (
    <div>
      <Route
        {...rest}
        component={(props: any) => {
          if (props.location.pathname.includes("/reset-password/")) {
            localStorage.clear();
            isLoggedInVar(false);
            return <Component {...props} />;
          }
          return !is_authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    </div>
  );
};

const PublicRoute = PublicRouteComponent;

export default PublicRoute;
