import { useMutation, useQuery } from "@apollo/client";
import arrayMove from "array-move";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SortableContainer } from "react-sortable-hoc";
import styled from "styled-components";
import { UPDATE_TOPIC_ORDERS } from "../../../apollo/mutations";
import { GET_TOPICS } from "../../../apollo/queries";
import { getTopics_topics } from "../../../apollo/queries/__generated__/getTopics";
import { theme } from "../../../utils/theme";
import { EmptyState } from "../../Segments/EmptyState";
import { Body, BUTTON_END, Col, Head, Row } from "../../UI";
import { NonSortableTopic, SortableTopic } from "./Topic";

export const Topics = () => {
  const [activeTopics, setActiveList] = useState<getTopics_topics[]>([]);
  const [inActiveTopics, setInactiveList] = useState<getTopics_topics[]>([]);

  const {
    loading: topicLoading,
    error: topicError,
    data,
  } = useQuery(GET_TOPICS, {
    fetchPolicy: "network-only",
    onCompleted: ({ topics }) => {
      setActiveList(topics.filter((el) => el.is_active === true));
      setInactiveList(topics.filter((el) => el.is_active === false));
    },
  });

  const [sortTopics, { loading: loadingOrders }] = useMutation(
    UPDATE_TOPIC_ORDERS,
    {
      variables: {
        data: [
          ...activeTopics.map((el: any) => el.id),
          ...inActiveTopics.map((el: any) => el.id),
        ],
      },
      onCompleted(data) {},
    }
  );

  if (topicLoading) return <p>loading ...</p>;
  if (topicError) return `topicError! ${topicError}`;

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const sorted = arrayMove(activeTopics, oldIndex, newIndex);
    setActiveList(sorted);
    sortTopics();
  };

  const SortableList = SortableContainer(({ items }: { items: any }) => {
    return (
      <Row>
        {items.map((value: getTopics_topics, index: number) => {
          return (
            <SortableTopic
              key={`item-${value.id}`}
              index={index}
              value={value}
              idx={index}
            />
          );
        })}
      </Row>
    );
  });

  const NonSortableList = ({ items }: { items: any }) => {
    {
      return (
        <Row>
          {items.map((value: getTopics_topics, index: number) => (
            <NonSortableTopic
              key={`item-${value.id}`}
              value={value}
              idx={index}
            />
          ))}
        </Row>
      );
    }
  };
  if (!data?.topics.length) {
    return (
      <EmptyState
        title="You have no existing topics"
        buttonTitle="+ Add New Topic"
        buttonLink={{
          pathname: "/topic/add",
          state: { catLen: data!.topics.length + 1 },
        }}
      />
    );
  }

  return (
    <Container className="container">
      <BUTTON_END to="topic/add"> Add New </BUTTON_END>
      <Table>
        <Head>
          <Row>
            <Col>Sort Order</Col>
            <Col>Title</Col>
            <Col>Total Sessions</Col>
            <Col>Total Series</Col>
            <Col>Is Active</Col>
            <Col>Edit</Col>
          </Row>
        </Head>
        <Body>
          <SortableList items={activeTopics} onSortEnd={onSortEnd} />
          <NonSortableList items={inActiveTopics} />
        </Body>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
`;

const LinkEnd = styled(Link)`
  align-self: flex-end;
`;

export const Table = styled.table`
  padding: 25px 15px;
  margin-top: 25px;
  align-self: center;
  text-align: center;
  display: grid;
  border-radius: 5px;
  background-color: #fff;
  border-collapse: unset;
  overflow: auto;
  grid-template-columns: 100px 300px repeat(4, 200px);
`;
