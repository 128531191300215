import { useQuery } from "@apollo/client";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { GET_ALL_PROMOTIONS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { Loading } from "../../UI";
import { Promotion } from "./Promotion";

export const Promotions = () => {
  const { loading, error, data } = useQuery(GET_ALL_PROMOTIONS);

  if (loading) return <Loading />;
  if (error) return `error! ${error}`;

  const promotion_datas = data.promoSections;

  const mapped_data = promotion_datas.map((promotion, i) => {
    return <Promotion promotion={promotion} key={i} />;
  });
  return <Container>{mapped_data}</Container>;
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  & > * {
    align-self: center;
  }
`;

const LinkEnd = styled(Link)`
  align-self: flex-end;
`;

export const Table = styled.table`
  padding: 25px 15px;
  margin-top: 25px;
  align-self: center;
  text-align: center;
  display: grid;
  border-radius: 5px;
  background-color: #fff;
  border-collapse: unset;
  overflow: auto;
  grid-template-columns: repeat(5, 240px);
`;
