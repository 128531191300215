import styled from "styled-components";
import { theme } from "../../utils/theme";

export interface InputProps {
  width?: number;
  fontSize?: number;
  color?: string;
  margin?: Array<number>;
  padding?: Array<number>;
}

export const TEXT_INPUT = styled.input<InputProps>`
  background: none;
  border: none;
  margin: ${(props) =>
    props.margin ? `${props.margin[0]}px ${props.margin[1]}px` : `38px 10px`};
  padding: ${(props) =>
    props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : `10px 5px`};
  width: ${(props) => props.width || 345}px;
  font-family: ${theme.PRIMARY_FONT};
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  color: ${(props) => props.color || theme.BLACK_COLOR};
  borderbottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &:focus {
    outline: none;
  }
  & > * {
    background: none;
  }
`;

export const TEXTAREA = styled.textarea<InputProps>`
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 580px;
  resize: none;
  font-size: 16px;
  padding: 8px 5px;
  margin: ${(props) =>
    props.margin ? `${props.margin[0]}px ${props.margin[1]}px` : `38px 10px`};
  padding: ${(props) =>
    props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : `10px 5px`};
  width: ${(props) => props.width || 345}px;
  min-height: "90px";
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => props.color || theme.BLACK_COLOR};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &:focus {
    outline: none;
  }
`;

export const ACTIVE_STATE_TEXT = styled.p<any>`
  color: ${(props) => (props.active ? `${theme.ACTIVE_COLOR}` : `inherit`)};
`;

export const EDIT_ICON = styled.p<any>`
  color: ${(props) => (props.active ? `${theme.ACTIVE_COLOR}` : `inherit`)};
`;
