import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";

// import { UPDATE_TOPIC_ORDERS } from "../../../apollo/mutations";
import { GET_ALL_SERIES } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { EmptyState } from "../../Segments/EmptyState";
import {
  ACTIVE_STATE_TEXT,
  Body,
  BUTTON_END,
  Col,
  EDIT_ICON_COL,
  Head,
  Row,
  TEXT_INPUT,
} from "../../UI";
import { fsyncSync } from "node:fs";
import {
  getSeries,
  getSeries_series,
} from "../../../apollo/queries/__generated__/getSeries";
// import {
//   getSeriess,
//   getSeriess_series,
// } from "../../../apollo/queries/__generated__/getSeriess";

export const AllSeries = () => {
  let history = useHistory();

  const {
    loading: seriesLoading,
    error: seriesError,
    data,
  } = useQuery<getSeries>(GET_ALL_SERIES, {
    // fetchPolicy: "no-cache",
    fetchPolicy: "network-only",
  });

  if (seriesLoading) return <p>loading ...</p>;
  if (seriesError) return `seriesError! ${seriesError}`;
  if (!data) return <p>Series Not found yet ...</p>;

  const handleEdit = (id) => {
    history.push(`/series/edit/${id}`);
  };

  return (
    <Container className="container">
      <BUTTON_END to="series/add"> Add New </BUTTON_END>
      <Table>
        <Head>
          <Row>
            <Col>Title</Col>
            <Col>Parent</Col>
            <Col>Is Active</Col>
            <Col>Edit</Col>
          </Row>
        </Head>
        <Body>
          {data.series.map((series, i) => {
            return (
              <Row key={i}>
                <Col>
                  <p>{series.title}</p>
                </Col>
                <Col>
                  <p>{series?.Topic?.title}</p>
                </Col>

                <Col>
                  <ACTIVE_STATE_TEXT active={series.is_active}>
                    {series.is_active ? "Active" : "Not Active"}
                  </ACTIVE_STATE_TEXT>
                </Col>

                <EDIT_ICON_COL
                  className="icon"
                  onClick={() => handleEdit(series.id)}
                >
                  <EditIcon />
                </EDIT_ICON_COL>
              </Row>
            );
          })}
        </Body>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
`;

const LinkEnd = styled(Link)`
  align-self: flex-end;
`;

export const Table = styled.table`
  padding: 25px 15px;
  margin-top: 25px;
  align-self: center;
  text-align: center;
  display: grid;
  border-radius: 5px;
  background-color: #fff;
  border-collapse: unset;
  overflow: auto;
  grid-template-columns: repeat(4, 300px);
`;
