import React, { Component } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

export interface ModalProps {
  showModal: boolean;
  // label: string;
  toggleModal: () => void;
  children: React.ReactNode;
  zIndex?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  marginTop?: string;
  marginBottom?: string;
}

export const Modal = (props: ModalProps) => {
  const {
    showModal,
    // label,
    toggleModal,
    children,
    zIndex = "10",
    marginBottom = "30px",
    marginTop = "30px",
    backgroundColor = "rgb(26, 26, 26, 0.4)",
  } = props;

  return (
    <StyledModal
      isOpen={showModal}
      ariaHideApp={false}
      zIndex={zIndex}
      marginBottom={marginBottom}
      marginTop={marginTop}
      backgroundColor={backgroundColor}
      onRequestClose={toggleModal}
      {...props}
    >
      {children}
    </StyledModal>
  );
};

function ReactModalAdapter({ className, children, ...props }: any) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    >
      {children}
    </ReactModal>
  );
}

interface AdditionalModalProps {
  width?: "940px";
  zIndex?: "10";
  backgroundColor?: "rgb(26, 26, 26, 0.4)";
  marginTop?: "30px";
  marginBottom?: "30px";
}

const StyledModal = styled(ReactModalAdapter)<AdditionalModalProps>`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${(props) => props.backgroundColor};
    z-index: ${(props) => props.zIndex};
    overflow-y: auto;
    max-height: 100%;
    outline: none;
  }
  &__content {
    width: ${(props) => props.width};
    margin: auto;
    background: #fff;
    overflow-y: auto;
    border-radius: 10px;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    -webkit-overflow-scrolling: touch;
    outline: none;
  }
`;
