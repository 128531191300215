import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React from "react";
import styled from "styled-components";
import { UPDATE_ONE_PROMO_SECTION } from "../../../apollo/mutations";
import { ImageUpload } from "../../Segments/ImageUpload";
import { Button, Err, SuccessMessage, TEXTAREA } from "../../UI";

export const Promotion = (props) => {
  const { promotion } = props;

  const [updateOnePromotion] = useMutation(UPDATE_ONE_PROMO_SECTION, {
    onCompleted({ updateOnePromoSection }) {
      SuccessMessage();
    },
  });

  const handleSubmit = (values) => {
    updateOnePromotion({
      variables: {
        data: {
          section: { set: values.section },
          title: { set: values.title },
          description: { set: values.description },
          button_text: { set: values.button_text },
          external_link: { set: values.external_link },
          image_url: { set: values.image_url },
        },
        where: {
          id: values.id,
        },
      },
    });
  };

  return (
    <Formik
      initialValues={{
        id: promotion.id || "",
        section: promotion.section || "",
        title: promotion.title || "",
        description: promotion.description || "",
        button_text: promotion.button_text || "",
        external_link: promotion.external_link || "",
        image_url: promotion.image_url || "",
      }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => {
        return (
          <FormikForm>
            <h2>{formik.values.section}</h2>

            <FlexRow>
              <InlineLabel>
                <p>Title: </p>
                <InputForm
                  type="text"
                  placeholder="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
              </InlineLabel>
              <InlineLabel>
                <p>Button Text: </p>
                <InputForm
                  type="text"
                  placeholder="button_text"
                  name="button_text"
                  value={formik.values.button_text}
                  onChange={formik.handleChange}
                />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                <p>Description: </p>
                <TEXTAREA
                  width={700}
                  margin={[0, 0]}
                  padding={[8, 0]}
                  placeholder="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </InlineLabel>
            </FlexRow>
            <FlexRow></FlexRow>
            <FlexRow>
              <InlineLabel>
                <p>External Link: </p>
                <InputForm
                  type="text"
                  placeholder="external_link"
                  name="external_link"
                  value={formik.values.external_link}
                  onChange={formik.handleChange}
                />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <ImageContainer>
                <p>Background Image: </p>
                <ImageUpload
                  key={promotion.id}
                  formik={formik}
                  no_header={true}
                  id={`${formik.values.id}`}
                />
                {formik.errors.image_url && formik.touched.image_url ? (
                  <Err>{formik.errors.image_url}</Err>
                ) : null}
              </ImageContainer>
            </FlexRow>
            <Button color="white" type="submit">
              Save {formik.values.section} Section
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin: 5px 0;
  }
`;

const FormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 30px 10px;
`;

const FormikFormRow = styled(Form)`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

const InputForm = styled.input`
  width: ${(props) => props.width || 500}px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
    color: #152935;
  }
`;

const ImageContainer = styled.div`
  display: inline-block;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
  }
`;
