import { gql } from "@apollo/client";

export const AdminFragment = gql`
  fragment Admin on User {
    id
    email
    session_token
    name
    login_type
  }
`;

export const TopicFragment = gql`
  fragment Topic on Topic {
    id
    title
    description
    image_url
    sort_order
    is_active
    sessions {
      id
      title
      sort_order
    }
    series {
      id
      title
      sort_order
    }
  }
`;
