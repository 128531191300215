import * as React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

export interface LoadingProps {
  color?: string;
  size?: number;
}

const Completed: React.FunctionComponent<LoadingProps> = () => {
  return (
    <Div>
      <h1>Reset Password Completed</h1>
      <p>Your account password has been reset.</p>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { Completed };
