import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { UPDATE_CUSTOM_SESSION } from "../../../apollo/mutations";
import { GET_ALL_CUSTOM_SESSIONS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { Loading } from "../../UI";
import { SingleSelect } from "../../UI/Selector";

// const CustomSessionBody = (props) => {
//   return (

//   )
// }
export const CustomSession = (props) => {
  const { custom_session, session_data, index, handleParentData } = props;
  const [value, setValue] = useState("");
  const [label, setLabel] = useState("");
  const [mutationLoading, setLoading] = useState(false);

  const [upsertCustomSession, { loading, error, data: custom_data }] =
    useMutation(UPDATE_CUSTOM_SESSION, {
      onCompleted({ updateCustomSession }) {
        const session = updateCustomSession.session;
        setValue(session.id);
        setLabel(session.title);
        handleParentData({
          ...custom_session,
          session_id: session.id,
          session_title: session.title,
        });
      },
    });

  useEffect(() => {
    setValue(custom_session.session_id);
    setLabel(custom_session.session_title);
  }, [props]);

  const handleChange = (data) => {
    const session_id = data.value;
    setValue(data.value);
    setLabel(data.label);
    upsertCustomSession({
      variables: {
        answer_ids: custom_session.answer_ids,
        session_id: session_id,
        custom_session_id: custom_session.custom_session_id,
      },
    });
  };

  return (
    <Row>
      {custom_session.row_values.map((el, i) => {
        {
          return index === 0 ? (
            <Col key={i}>
              <p>
                <b>{el}</b>
              </p>
            </Col>
          ) : (
            <Col key={i}>
              <p>{el}</p>
            </Col>
          );
        }
      })}
      {index === 0 ? (
        <Col>
          <p>
            <b>Custom Session</b>
          </p>
        </Col>
      ) : (
        <Col>
          <SingleSelect
            data={session_data || []}
            default_label={label}
            default_value={value}
            loading={mutationLoading}
            custom_session_data={custom_session}
            handleChange={handleChange}
          />
        </Col>
      )}
    </Row>
  );
};

const Row = styled.tr`
  display: contents;
  padding: 15px;
`;

const Col = styled.td`
  border-bottom: solid 1.5px #e7e7e7;
  padding: 15px 0;
`;
