import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { FETCH_USER_VIA_CODE } from "../../../apollo/queries";
import {
  Button,
  CustomizedErrorComponent,
  Err,
  Loading,
  SwalError,
} from "../../UI";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { theme } from "../../../utils/theme";
import { RESET_PASSWORD } from "../../../apollo/mutations";
import { useHistory } from "react-router-dom";
import { values } from "lodash";
import { NotFound } from "../../UI/NotFound";

export const ResetPage = (props) => {
  const code = props.match.params.code;

  const history = useHistory();
  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password is too short. Minimum Password length is 8")
      .required("Password is required"),
    repeatPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      history.push("/reset-password/completed");
    },
    onError: () => {
      history.push("/404");
    },
  });
  const { data, loading, error } = useQuery(FETCH_USER_VIA_CODE, {
    variables: {
      code: code,
    },
  });

  if (loading) return <Loading />;
  if (error) {
    SwalError(error.message);
    console.log(error);

    return (
      <CustomizedErrorComponent
        header={"Code Error"}
        body={
          "Code is not valid. Please check your code or send password reset email again."
        }
      />
    );
  }

  const handleSubmit = (formik) => {
    const values = formik.values;
    if (formik.isValid && values.password.length > 7) {
      resetPassword({
        variables: {
          code,
          email: values.email,
          password: values.password,
        },
      });
    } else {
      SwalError("Please Check the Error Messages");
    }
  };
  const user = data.fetchUserViaCode;
  return (
    <Formik
      initialValues={{
        email: user.email,
        password: "",
        repeatPassword: "",
      }}
      validationSchema={resetSchema}
      onSubmit={(values) => {}}
    >
      {(formik) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(formik);
          }}
        >
          <Container>
            <h2>Reset account password</h2>
            <p> Enter a new password for {user.email}</p>
            <InlineLabel>
              <p>Password </p>
              <InputForm
                type="password"
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.errors.password ? (
                <Err>{formik.errors.password}</Err>
              ) : null}
            </InlineLabel>
            <InlineLabel>
              <p>Confirm Password</p>
              <InputForm
                type="password"
                placeholder="Confirm Password"
                name="repeatPassword"
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
              />
              {formik.errors.repeatPassword ? (
                <Err>{formik.errors.repeatPassword}</Err>
              ) : null}
            </InlineLabel>
            <Button color={"#fff"} backgroundColor={"#5c5f62"} type="submit">
              Reset Password
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 70%;
  padding: 10px 5px;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
  }
`;

const InputForm = styled.input`
  width: 259px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;
