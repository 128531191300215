import { gql, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import styled from "styled-components";
import * as Yup from "yup";
import { theme } from "../../../utils/theme";
import { ImageUpload } from "../../Segments/ImageUpload";
import {
  BACK_BUTTON,
  Err,
  Loading,
  Row,
  SAVE_BUTTON,
  SuccessMessage,
  Switch,
  TEXTAREA,
} from "../../UI";
import { ReactComponent as BurgerIcon } from "../../../images/burgerIcon.svg";

import { DeleteTopic } from "./DeleteTopic";
import { UPDATE_CHILD_SORT_ORDER } from "../../../apollo/mutations";

const topicSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  is_active: Yup.boolean(),
  image_url: Yup.string().required("Image is required!"),
});

const SortableChild = SortableElement(
  ({ value, index, idx }: { value: any; index: number; idx: number }) => {
    const { id, title, __typename } = value;
    return (
      <p>
        <Burger />
        {__typename} : {title}
      </p>
    );
  }
);
const SortableList = SortableContainer(({ items }: { items: any }) => {
  return (
    <div>
      {items.map((value: any, index: number) => {
        return (
          <SortableChild
            key={`item-${value.id}`}
            index={index}
            value={value}
            idx={index}
          />
        );
      })}
    </div>
  );
});

export const TopicForm = ({
  topic,
  topicId,
  catMutation,
}: {
  topic?: any | null;
  topicId?: number | null | undefined;
  catMutation: any;
}) => {
  let history = useHistory();

  const [uploaded, setUploadStatus] = useState(false);
  const [child_objects, setChild] = useState([]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const sorted = arrayMove(child_objects, oldIndex, newIndex);
    setChild(sorted);
  };

  useEffect(() => {
    const child = handleChildObject(topic?.sessions, topic?.series);

    setChild(child);
  }, [topic]);

  // formik

  const [sortMutation, { loading: sort_loading }] = useMutation(
    UPDATE_CHILD_SORT_ORDER,
    {}
  );
  const [topicMutation, { loading }] = useMutation(catMutation, {
    onCompleted(data) {
      SuccessMessage();
      history.push(`/topics`);
    },
  });

  const handleSubmit = (values) => {
    if (!topicId) {
      topicMutation({
        variables: {
          data: {
            title: values.title,
            image_url: values.image_url,
            is_active: values.is_active,
            description: values.description,
          },
        },
      });
    } else {
      topicMutation({
        variables: {
          data: {
            title: { set: values.title },
            image_url: { set: values.image_url },
            is_active: { set: values.is_active },
            description: { set: values.description },
          },
          where: { id: topicId },
        },
      });
    }

    if (child_objects.length) {
      sortMutation({
        variables: {
          topic_id: topicId,
          child_objects: child_objects.map((el: any) => {
            return {
              object_type: el.__typename,
              object_id: Number(el.id),
            };
          }),
        },
      });
    }
  };

  const uploadText = uploaded ? "Replace Banner Image" : "Upload";

  if (loading) {
    return <Loading />;
  }

  const handleChildObject = (sessions, series) => {
    if (!sessions && !series) {
      return [];
    }
    const res = sessions.concat(series);
    const sorted = res.sort((a: any, b: any) => {
      return a.sort_order - b.sort_order;
    });
    return sorted;
  };

  console.log("child_objects", child_objects);

  return (
    <Container>
      <BACK_BUTTON to="/topics">
        <FaChevronLeft fontSize={20} />
        <p>Back to Topics</p>
      </BACK_BUTTON>

      <Formik
        initialValues={{
          title: topic ? topic.title : "",
          is_active: topic ? topic.is_active : false,
          child_objects: topic ? topic.series : [],
          description: topic ? topic.description : "",
          image_url: topic ? topic.image_url : "",
        }}
        validationSchema={topicSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            <FlexRow>
              <InlineLabel>
                <p>Topic Title</p>
                <InputForm
                  type="text"
                  placeholder="Topic Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && formik.touched.title ? (
                  <Err>{formik.errors.title}</Err>
                ) : null}
              </InlineLabel>
              <InlineLabel>
                <p>Status </p>
                <Selector
                  className="selector"
                  name="is_active"
                  class
                  value={{
                    value: formik.values.is_active,
                    label: formik.values.is_active ? "Active" : "Not Active",
                  }}
                  onChange={(e) => formik.setFieldValue("is_active", e?.value)}
                  options={[
                    { value: true, label: "Active" },
                    {
                      value: false,
                      label: "Not Active",
                    },
                  ]}
                />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                <p>Topic Description </p>
                <TEXTAREA
                  width={600}
                  margin={[20, 0]}
                  padding={[8, 0]}
                  placeholder="Topic Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />

                {formik.errors.description && formik.touched.description ? (
                  <Err>{formik.errors.description}</Err>
                ) : null}
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                {formik.errors.image_url && formik.touched.image_url ? (
                  <Err>{formik.errors.image_url}</Err>
                ) : null}
                <ImageUpload formik={formik} />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                <p>Series / Subtopics / Sessions</p>
                <SortableList items={child_objects} onSortEnd={onSortEnd} />
              </InlineLabel>
            </FlexRow>
            <LastSection>
              <SAVE_BUTTON type="submit">Save</SAVE_BUTTON>
              <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
                Cancel
              </SAVE_BUTTON>
              {topicId ? <DeleteTopic topicId={topicId} /> : null}
            </LastSection>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
}

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  max-width: 1200px;
  width: 95%;
  margin: auto;
  background-color: #fff;
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  display: grid;
  grid-gap: 10px;
`;

const Selector = styled(Select).attrs({ activeClassName: "selector" })`
  width: 200px;
`;

const InputForm = styled.input`
  width: 259px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
  }
`;

const LastSection = styled.section`
  margin: auto;
  grid-row: 3;
  text-align: center;
`;

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin-left: 26px;
  }
`;

const Burger = styled(BurgerIcon)`
  display: inline;
  margin-bottom: -6px;
`;
