import React from "react";
import styled from "styled-components";
import logo from "./logo.svg";
import AppRouter from "./routers/AppRouter";
import { theme } from "./utils/theme";

function App() {
  return (
    <Container>
      <header className="App-header">
        <AppRouter />
      </header>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${theme.BACKGROUND_COLOR};
  min-height: calc(100vh);
  min-width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
`;

export default App;
