import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { EDIT_ONE_TOPIC } from "../../../apollo/mutations";
import { GET_TOPIC } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { TopicForm } from "./TopicForm";

export const EditTopic = (props: any) => {
  const topicId = Number(props.match.params.topicId);

  const { data, loading, error } = useQuery(GET_TOPIC, {
    variables: {
      data: {
        id: topicId,
      },
      fetchPolicy: "network-only",
    },
  });

  if (loading) return <Loading />;

  return (
    <TopicForm
      topic={data?.topic}
      topicId={topicId}
      catMutation={EDIT_ONE_TOPIC}
    />
  );
};
