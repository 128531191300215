import React from "react";
import { useHistory } from "react-router-dom";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { getTopics_topics } from "../../../apollo/queries/__generated__/getTopics";
import { ReactComponent as BurgerIcon } from "../../../images/burgerIcon.svg";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { ACTIVE_STATE_TEXT, Button, Col, EDIT_ICON_COL } from "../../UI";

const Draggable = SortableHandle(() => (
  <Con>
    <Burger />
  </Con>
));

export const SortableTopic = SortableElement(
  ({
    value,
    index,
    idx,
  }: {
    value: getTopics_topics;
    index: number;
    idx: number;
  }) => {
    let history = useHistory();

    const { id, image_url, title, is_active, sessions, series } = value;
    const handleClick = () => {
      history.push(`/workouts/${id}`);
    };

    const handleEdit = () => {
      history.push(`/topic/edit/${id}`);
    };

    const activeStatus = is_active ? "Active" : "Not Active";
    return (
      <>
        <Col>
          <SortIndex>{idx + 1}</SortIndex>
          <Burger />
        </Col>
        <Col>
          <p>{title}</p>
        </Col>
        <Col>
          <p>{sessions.length}</p>
        </Col>
        <Col>
          <p>{series.length}</p>
        </Col>
        <Col>
          <ACTIVE_STATE_TEXT active={is_active}>
            {activeStatus}
          </ACTIVE_STATE_TEXT>
        </Col>

        <EDIT_ICON_COL className="icon" onClick={() => handleEdit()}>
          <EditIcon />
        </EDIT_ICON_COL>
      </>
    );
  }
);

export const NonSortableTopic = ({
  value,
  idx,
}: {
  value: any;
  idx: number;
}) => {
  let history = useHistory();

  const { id, image_url, title, is_active, sessions, series } = value;
  const handleClick = () => {
    history.push(`/workouts/${id}`);
  };

  const handleEdit = () => {
    history.push(`/topic/edit/${id}`);
  };

  const activeStatus = is_active ? "Active" : "Not Active";
  return (
    <>
      <Col>
        <SortIndex>{idx + 1}</SortIndex>
        <Burger />
      </Col>
      <Col>
        <p>{title}</p>
      </Col>
      <Col>
        <p>{sessions.length}</p>
      </Col>
      <Col>
        <p>{series.length}</p>
      </Col>
      <Col>
        <p>{activeStatus}</p>
      </Col>
      <EDIT_ICON_COL className="icon" onClick={() => handleEdit()}>
        <EditIcon />
      </EDIT_ICON_COL>
    </>
  );
};

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const Burger = styled(BurgerIcon)`
  display: inline;
  margin-bottom: -6px;
`;

const Con = styled.span`
  display: inline;
`;

const SortIndex = styled.div`
  width: 25px;
  text-align: center;
  display: block;
`;

const NonSortIndex = styled.div`
  width: 73px;
  text-align: center;
  display: block;
`;
