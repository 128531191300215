import { useMutation } from "@apollo/client";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DELETE_ONE_SESSION } from "../../../apollo/mutations";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeleteSession = (props: any) => {
  // local set
  const sessionId = props.sessionId;
  let history = useHistory();

  const [deleteOneSession, { loading }] = useMutation(DELETE_ONE_SESSION, {
    onCompleted(data) {
      history.push(`/sessions`);
    },
  });

  const handleDelete = () => {
    deleteOneSession({
      variables: {
        where: { id: sessionId },
      },
    });
  };

  if (loading) return <Loading />;

  // render

  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>Delete Session</p>
    </DELETE_BUTTON>
  );
};
