import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { Login } from "../Components/Pages/Login";
import { Topics } from "../Components/Pages/Topics/Topics";
import { AddTopic } from "../Components/Pages/Topics/AddTopic";
import { EditTopic } from "../Components/Pages/Topics/EditTopic";

import { Sessions } from "../Components/Pages/Sessions/Sessions";
import { Folders } from "../Components/Pages/Folders/Folders";
import { AddFolder } from "../Components/Pages/Folders/AddFolder";
import { EditFolder } from "../Components/Pages/Folders/EditFolder";
import { AddSession } from "../Components/Pages/Sessions/AddSession";
import { EditSession } from "../Components/Pages/Sessions/EditSession";
import { AllSeries } from "../Components/Pages/Series/AllSeries";
import { AddSeries } from "../Components/Pages/Series/AddSeries";
import { EditSeries } from "../Components/Pages/Series/EditSeries";
import { CustomSessions } from "../Components/Pages/CustomSessions/CustomSessions";
import { CustomManager } from "../Components/Pages/CustomSessions/CustomManager";
import { Promotions } from "../Components/Pages/Promotions/Promotions";
import { PremiumUsers } from "../Components/Pages/Premium/PremiumUsers";
import { ResetPage } from "../Components/Pages/PasswordReset/ResetPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { NotFound } from "../Components/UI/NotFound";
import { Completed } from "../Components/UI/Complete";
import { Users } from "../Components/Pages/Users/Users";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact component={Topics} />
        <PrivateRoute path="/topics" exact component={Topics} />
        <PrivateRoute path="/topic/add" exact component={AddTopic} />
        <PrivateRoute path="/topic/edit/:topicId" exact component={EditTopic} />

        <PrivateRoute path="/folders" exact component={Folders} />
        <PrivateRoute path="/folders/add" exact component={AddFolder} />
        <PrivateRoute
          path="/folders/edit/:folderId"
          exact
          component={EditFolder}
        />

        <PrivateRoute path="/sessions" exact component={Sessions} />
        <PrivateRoute path="/session/add" exact component={AddSession} />
        <PrivateRoute
          path="/session/edit/:sessionId"
          exact
          component={EditSession}
        />

        <PrivateRoute path="/series" exact component={AllSeries} />
        <PrivateRoute path="/series/add" exact component={AddSeries} />
        <PrivateRoute
          path="/series/edit/:seriesId"
          exact
          component={EditSeries}
        />

        <PrivateRoute path="/custom" exact component={CustomSessions} />
        <PrivateRoute path="/custom/edit" exact component={CustomManager} />
        <PrivateRoute path="/promotions" exact component={Promotions} />
        <PrivateRoute
          path="/custom/edit/:questionId"
          exact
          component={CustomManager}
        />

        <PrivateRoute path="/premium" exact component={PremiumUsers} />
        <PrivateRoute path="/users" exact component={Users} />

        <PublicRoute path="/login" exact component={Login} />
        <PublicRoute path="/reset-password/code=:code" component={ResetPage} />
        <PublicRoute path="/reset-password/completed" component={Completed} />
        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
