import { useQuery } from "@apollo/client";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { client } from "../apollo";
import { IS_LOGGED_IN } from "../apollo/queries/index";
import { Header } from "../Components/Segments/Header";
import { Loading } from "../Components/UI";

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PrivateRouteProps;

export const PrivateRouteComponent = ({
  component: Component,
  path,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  const { data, loading } = useQuery(IS_LOGGED_IN);

  if (loading) {
    return <Loading />;
  }
  const is_authenticated = data.isLoggedIn;
  return (
    <div>
      <Header />
      <Route
        {...rest}
        component={(props) => {
          if (is_authenticated) {
            console.log(props.location);

            return <Component {...props} />;
          } else {
            if (props.location.pathname === "reset-password") {
              return <Component {...props} />;
            }
            return <Redirect to="/login" />;
          }
        }}
      />
    </div>
  );
};

const PrivateRoute = PrivateRouteComponent;

export default PrivateRoute;
