import { useMutation } from "@apollo/client";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DELETE_ONE_SERIES } from "../../../apollo/mutations";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeleteSeries = (props: any) => {
  // local set
  const seriesId = props.seriesId;
  let history = useHistory();

  const [deleteOneSeries, { loading }] = useMutation(DELETE_ONE_SERIES, {
    onCompleted(data) {
      history.push(`/series`);
    },
  });

  const handleDelete = () => {
    deleteOneSeries({
      variables: {
        where: { id: seriesId },
      },
    });
  };

  if (loading) return <Loading />;

  // render

  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>Delete Series</p>
    </DELETE_BUTTON>
  );
};
