import React from "react";
import styled from "styled-components";
import { FaGoogle, FaFacebook, FaApple } from "react-icons/fa";
import { Button, DeleteMessage, SwalConfirmWarning } from "../../UI";
import { DELETE_ONE_USER } from "../../../apollo/mutations";
import { GET_ALL_USERS } from "../../../apollo/queries";
import { useMutation } from "@apollo/client";
import { theme } from "../../../utils/theme";

interface UserProps {
  data: {
    id: number;
    email: string;
    login_type: "InApp" | "Facebook" | "Google" | "Apple";
  };
}

export const User: React.FC<UserProps> = ({ data }) => {
  const { id, email, login_type } = data;

  const [deleteUser] = useMutation(DELETE_ONE_USER, {
    onCompleted({ updateOnePromoSection }) {
      DeleteMessage();
    },
    refetchQueries: [{ query: GET_ALL_USERS }],
    awaitRefetchQueries: true,
  });

  const handleDelete = async () => {
    const confirm = await SwalConfirmWarning(
      `Are you sure you want to delete ${data.email}?`
    );
    if (!confirm) {
      return;
    }
    deleteUser({
      variables: {
        user_id: id,
      },
    });
  };

  return (
    <Container>
      <p>{email}</p>
      <FlexDiv>
        <FaGoogle
          color={login_type === "Google" ? "#EA4335" : theme.LIGHT_GREY_COLOR}
        />
        <FaApple
          color={login_type === "Apple" ? "black" : theme.LIGHT_GREY_COLOR}
        />
        <FaFacebook
          color={login_type === "Facebook" ? "#1977F3" : theme.LIGHT_GREY_COLOR}
        />
      </FlexDiv>
      <Button
        backgroundColor={"#d0021b"}
        color={"#fff"}
        type="button"
        onClick={handleDelete}
      >
        delete
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto 100px;
  align-items: center;
  width: 100%;

  margin-top: 8px;
  padding: 4px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 12px;
`;
