import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import { identity } from "lodash";
import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import styled from "styled-components";
import * as Yup from "yup";
import { UPDATE_SESSION_SORT_ORDER } from "../../../apollo/mutations";
import { GET_TOPICS } from "../../../apollo/queries";
import { ReactComponent as BurgerIcon } from "../../../images/burgerIcon.svg";
import { theme } from "../../../utils/theme";
import { ImageUpload } from "../../Segments/ImageUpload";
import {
  BACK_BUTTON,
  Err,
  Loading,
  SAVE_BUTTON,
  SuccessMessage,
  TEXTAREA,
} from "../../UI";
import { Selector } from "../../UI/Selector";
import { DeleteSeries } from "./DeleteSeries";

const SeriesValidationForm = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  is_active: Yup.boolean(),
  image_url: Yup.string().required("Image is required!"),
});
const SortableChild = SortableElement(
  ({ value, index, idx }: { value: any; index: number; idx: number }) => {
    const { id, title } = value;
    return (
      <p>
        <Burger />
        {title}
      </p>
    );
  }
);
const SortableList = SortableContainer(({ items }: { items: any }) => {
  return (
    <div>
      {items.map((value: any, index: number) => {
        return (
          <SortableChild
            key={`item-${value.id}`}
            index={index}
            value={value}
            idx={index}
          />
        );
      })}
    </div>
  );
});

export const SeriesForm = ({
  series,
  seriesId,
  catMutation,
}: {
  series?: any;
  seriesId?: number | null | undefined;
  catMutation: any;
}) => {
  let history = useHistory();

  const [uploaded, setUploadStatus] = useState(false);
  const [sessions, setSessions] = useState([]);

  // formik

  useEffect(() => {
    const sorted = series
      ? series.sessions.slice().sort((a, b) => {
          return a.sort_order - b.sort_order;
        })
      : [];

    setSessions(sorted);
  }, [series]);

  const [seriesMutation, { loading }] = useMutation(catMutation, {
    onCompleted(data) {
      SuccessMessage().then(() => {
        history.push(`/series`);
      });
    },
  });

  const {
    data: all_topics,
    loading: topics_loading,
    error: topics_error,
  } = useQuery(GET_TOPICS, {
    fetchPolicy: "network-only",
  });

  const [sortMutation, { loading: sort_loading }] = useMutation(
    UPDATE_SESSION_SORT_ORDER,
    {}
  );

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const sorted = arrayMove(sessions, oldIndex, newIndex);
    setSessions(sorted);
  };

  const handleSubmit = (values) => {
    if (!seriesId) {
      seriesMutation({
        variables: {
          data: {
            title: values.title,
            image_url: values.image_url,
            is_active: values.is_active,
            description: values.description,
            days_description: values.days_description,
            Topic: {
              connect: { id: values.topic_id },
            },
          },
        },
      });
    } else {
      seriesMutation({
        variables: {
          data: {
            title: { set: values.title },
            image_url: { set: values.image_url || "" },
            is_active: { set: values.is_active },
            description: { set: values.description },
            days_description: { set: values.days_description },
            Topic: {
              connect: { id: values.topic_id },
            },
          },
          where: { id: seriesId },
        },
      });
    }
    if (sessions.length) {
      sortMutation({
        variables: {
          series_id: seriesId,
          sessions: sessions.map((el: any) => {
            return {
              session_id: el.id,
            };
          }),
        },
      });
    }
  };

  if (loading || topics_loading) {
    return <Loading />;
  }

  const session_list = sessions.map((el: any, i) => {
    return <li key={i}>{el ? el.title : ""}</li>;
  });

  return (
    <Container>
      <BACK_BUTTON to="/topics">
        <FaChevronLeft fontSize={20} />
        <p>Back to Topics</p>
      </BACK_BUTTON>

      <Formik
        initialValues={{
          title: series ? series.title : "",
          is_active: series ? series.is_active : false,
          description: series ? series.description : "",
          days_description: series ? series.days_description : "",
          image_url: series ? series.image_url : "",
          topic_id: series ? series.topic_id : "",
          topic_title: series && series.Topic ? series.Topic.title : "",
        }}
        validationSchema={SeriesValidationForm}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            <FlexRow>
              <InlineLabel>
                <p>Series Title</p>
                <InputForm
                  type="text"
                  placeholder="Series Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && formik.touched.title ? (
                  <Err>{formik.errors.title}</Err>
                ) : null}
              </InlineLabel>
              <InlineLabel>
                <p>Days in a Series</p>
                <InputForm
                  type="text"
                  placeholder="Series Days"
                  name="days_description"
                  value={formik.values.days_description}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && formik.touched.title ? (
                  <Err>{formik.errors.title}</Err>
                ) : null}
              </InlineLabel>

              <InlineLabel>
                <p>Status </p>
                <Selector
                  name="is_active"
                  field="is_active"
                  default_value={formik.values.is_active}
                  default_label={
                    formik.values.is_active ? "Active" : "Not Active"
                  }
                  formik={formik}
                  option_data={[
                    { value: true, label: "Active" },
                    {
                      value: false,
                      label: "Not Active",
                    },
                  ]}
                />
              </InlineLabel>
              <InlineLabel>
                <p> Parent Topic </p>
                <Selector
                  name="topic_id"
                  field="topic_id"
                  default_value={formik.values.topic_id}
                  default_label={formik.values.topic_title}
                  formik={formik}
                  option_data={
                    all_topics
                      ? all_topics.topics.map((el) => {
                          return { value: el.id, label: el.title };
                        })
                      : []
                  }
                />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                <p>Series Description </p>
                <TEXTAREA
                  width={600}
                  margin={[20, 0]}
                  padding={[8, 0]}
                  placeholder="Series Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />

                {formik.errors.description && formik.touched.description ? (
                  <Err>{formik.errors.description}</Err>
                ) : null}
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                {formik.errors.image_url && formik.touched.image_url ? (
                  <Err>{formik.errors.image_url}</Err>
                ) : null}
                <ImageUpload formik={formik} />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <SortableList items={sessions} onSortEnd={onSortEnd} />
            </FlexRow>

            <LastSection>
              <SAVE_BUTTON type="submit">Save</SAVE_BUTTON>
              <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
                Cancel
              </SAVE_BUTTON>
              {seriesId ? <DeleteSeries seriesId={seriesId} /> : null}
            </LastSection>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
}

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  max-width: 1200px;
  width: 95%;
  margin: auto;
  background-color: #fff;
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  display: grid;
  grid-gap: 10px;
`;

const InputForm = styled.input`
  width: 259px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const TextAreaForm = styled.input`
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 580px;
  resize: none;
  height: 90px;
  font-size: 16px;
  padding: 8px 5px;
  vertical-align: top;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
  }
`;

const FirstSection = styled.section`
  margin: 36px 51px;
  grid-row: 1;
`;
const SecondSection = styled.section`
  margin: 36px 51px;
  grid-row: 2;
`;

const LastSection = styled.section`
  margin: auto;
  grid-row: 3;
  text-align: center;
`;

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin-left: 26px;
  }
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;

const Burger = styled(BurgerIcon)`
  display: inline;
  margin-bottom: -6px;
`;
