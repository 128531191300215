import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { CREATE_ONE_FOLDER } from "../../../apollo/mutations";
import { createOneFolder } from "../../../apollo/mutations/__generated__/createOneFolder";
import { theme } from "../../../utils/theme";
import { Button, Err } from "../../UI";

const FolderSchema = Yup.object().shape({
  name: Yup.string().required("name is required!"),
});

export const AddFolder = ({ closeModal }: { closeModal: () => void }) => {
  const historyTest = useHistory();

  const [addFolder] = useMutation<createOneFolder>(CREATE_ONE_FOLDER, {
    onCompleted: ({ createOneFolder }) => {
      historyTest.push(`folders/edit/${createOneFolder.id}`);
    },
  });

  const handleSubmit = (name) => {
    addFolder({
      variables: {
        data: {
          name: name,
        },
      },
    });
  };

  return (
    <Container>
      <Formik
        initialValues={{ name: "" }}
        onSubmit={(values) => {
          handleSubmit(values.name);
        }}
        validationSchema={FolderSchema}
      >
        {(formik) => (
          <StyledForm>
            <FlexRow>
              <InlineLabel>
                <p>New Folder </p>
                <InputForm
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Err>{formik.errors.name}</Err>
                ) : null}
              </InlineLabel>
            </FlexRow>
            <FlexButtonRow>
              <CustomButton backgroundColor="#6c757d" onClick={closeModal}>
                Close
              </CustomButton>
              <CustomButton type="submit">Add</CustomButton>
            </FlexButtonRow>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: auto;
  display: flex;
  justify-content: left;
`;

const InputForm = styled.input`
  width: 250px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
  margin: 3px 5px 18px 5px;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 10px;
    margin: 0 0 7px;
  }
`;

const StyledForm = styled(Form)`
  width: 500px;
`;

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin-left: 26px;
  }
`;

const FlexButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ButtonProps {
  backgroundColor?: string;
}
const CustomButton = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#0089b7"};
  text-decoration: none;
  border: 1px solid black;
  padding: 8px 15px;
  margin: 0px 13px 10px 3px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
`;
