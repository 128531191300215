import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import { UPDATE_CUSTOM_SESSION } from "../../apollo/mutations";
import {
  GET_ALL_CUSTOM_SESSIONS,
  GET_TOPICS,
  GET_SESSIONS,
} from "../../apollo/queries";
import { theme } from "../../utils/theme";
import { Loading } from "./Loading";

export const Selector = (props) => {
  let { field, formik, option_data, default_value, default_label } = props;

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(props.default);

  useEffect(() => {
    setOptions(option_data);
  }, [props]);

  const handleTypeSelect = (e) => {
    formik.setFieldValue(field, e.value);
    setSelectedOption(e.value);
  };

  if (!options) {
    return <Loading />;
  }

  return (
    <StyledSelector
      onChange={handleTypeSelect}
      options={options}
      className="selector"
      type="text"
      defaultValue={{
        value: default_value,
        label: default_label,
      }}
    />
  );
};

const animatedComponents = makeAnimated();

export const SingleSelect = (props) => {
  let { id, default_label, default_value, data, loading, handleChange } = props;

  if (loading) {
    <Loading />;
  }
  let defaultData = { value: default_value, label: default_label };

  const optionData = data.sessions.map((el) => {
    return { value: el.id, label: el.title };
  });

  return (
    <div>
      <Select
        key={id}
        closeMenuOnSelect={true}
        value={defaultData}
        components={animatedComponents}
        options={optionData}
        onChange={handleChange}
        placeholder="Custom Session"
      />
    </div>
  );
};

export const MultiSelect = (props) => {
  let { id, data, loading, handleChange } = props;

  if (loading) {
    <Loading />;
  }

  const optionData = data.map((el) => {
    return { value: el.id, label: el.answer };
  });

  return (
    <div>
      <Select
        closeMenuOnSelect={true}
        isMulti
        components={animatedComponents}
        options={optionData}
        onChange={handleChange}
        placeholder="Filters"
      />
    </div>
  );
};

export interface SelectorProps {
  wtitleth?: number;
  fontSize?: number;
  color?: string;
  margin?: Array<number>;
  padding?: Array<number>;
}

const StyledSelector = styled(Select).attrs({ activeClassName: "selector" })`
  width: 200px;
`;
