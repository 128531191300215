export const parent_options = (data) => {
  if (!data) {
    return [];
  }

  const labels: string[] = data.map((el) => el.parent_label);
  let unique_labels = [...new Set(labels)];
  return unique_labels.reduce((acc: any, el: string) => {
    if (el) {
      acc.push({ label: el, value: el });
    }
    return acc;
  }, []);
};
