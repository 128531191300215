import { useMutation } from "@apollo/client";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DELETE_ONE_TOPIC } from "../../../apollo/mutations";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeleteTopic = (props: any) => {
  // local set
  const topicId = props.topicId;
  let history = useHistory();

  const [deleteOneCateogry, { loading }] = useMutation(DELETE_ONE_TOPIC, {
    onCompleted(data) {
      history.push(`/topics`);
    },
  });

  const handleDelete = () => {
    deleteOneCateogry({
      variables: {
        where: { id: topicId },
      },
    });
  };

  if (loading) return <Loading />;

  // render

  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>Delete Topic</p>
    </DELETE_BUTTON>
  );
};
