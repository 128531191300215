import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GET_FOLDERS } from "../../../apollo/queries";
import { getFolders_folders } from "../../../apollo/queries/__generated__/getFolders";
import { Button, BUTTON_END, Loading } from "../../UI";
import { Modal } from "../../UI/AppModal";
import { AddFolder } from "./AddFolder";
import { ReactComponent as FolderImage } from "../../../images/folder.svg";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { width } from "@material-ui/system";

export const Folders = () => {
  let history = useHistory();
  const [folders, setFolders] = useState<getFolders_folders[]>([]);
  const [modal, showModal] = useState<boolean>(false);

  const { loading, error, data } = useQuery(GET_FOLDERS, {
    fetchPolicy: "network-only",
    onCompleted: ({ folders }) => {
      setFolders(folders);
    },
  });

  if (loading) {
    return <Loading />;
  }

  const handleEdit = (id) => {
    history.push(`/folders/edit/${id}`);
  };

  const folderList = () => {
    if (!folders.length) {
      return <p>No Data Found</p>;
    }
    return folders.map((el, i) => {
      return (
        <InlineLabel key={i} onClick={() => handleEdit(el.id)}>
          <FolderIcon />
          <p>{el.name}</p>
        </InlineLabel>
      );
    });
  };

  return (
    <div>
      <Container>
        <FlexEnd>
          <ButtonEnd onClick={() => showModal(!modal)}> Add New </ButtonEnd>
        </FlexEnd>
        <Modal
          children={<AddFolder closeModal={() => showModal(false)} />}
          showModal={modal}
          width="500px"
          marginTop="300px"
          toggleModal={() => showModal(!modal)}
        />
        <Grid>{folderList()}</Grid>
      </Container>
    </div>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  max-width: 900px;
  width: 95%;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
`;

const FolderIcon = styled(FolderImage)`
  width: 45px;
  height: 45px;
  margin-right: 10px;
  color: yellow;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  justify-items: center;
  background-color: #fff;
  color: #444;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  cursor: pointer;
  p {
    width: 50px;
    font-size: 14px;
    margin: 0 0 7px;
    text-align: center;
  }
`;

const FlexEnd = styled.div`
  width: 105%;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  justify-content: flex-end;
  background-color: #f6f6f6;
  margin-bottom: 30px;
  padding-bottom: 30px;
`;
const ButtonEnd = styled.button`
  align-self: flex-end;
  text-decoration: none;
  border: 1px solid black;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #0089b7;
  color: #fff;
  border: none;
  border-radius: 4px;
`;
