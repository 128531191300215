import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import * as Yup from "yup";
import {
  ADD_OR_EDIT_FOLDER_SESSIONS,
  DELETE_ONE_FOLDER,
} from "../../../apollo/mutations";
import {
  addOrEditFolderSessions,
  addOrEditFolderSessionsVariables,
} from "../../../apollo/mutations/__generated__/addOrEditFolderSessions";
import {
  deleteOneFolder,
  deleteOneFolderVariables,
} from "../../../apollo/mutations/__generated__/deleteOneFolder";
import { fetchFolderSessionOptions_fetchFolderSessionOptions } from "../../../apollo/queries/__generated__/fetchFolderSessionOptions";
import { getFolder_folder_sessions } from "../../../apollo/queries/__generated__/getFolder";
import { parent_options } from "../../../utils/functions";
import { theme } from "../../../utils/theme";
import { SAVE_BUTTON } from "../../UI";

const FolderSessionSchema = Yup.object().shape({
  name: Yup.string().required("name is required!"),
});

export const AddOrEditSession = ({
  folderId,
  sessionData,
  sessionOptions,
  closeModal,
}: {
  sessionData: getFolder_folder_sessions[] | null | undefined;
  folderId: number;
  sessionOptions: fetchFolderSessionOptions_fetchFolderSessionOptions[];
  closeModal: () => void;
}) => {
  const history = useHistory();
  const [childSessions, setChildSessions] = useState<{
    existing_sessions: any[];
    deleted_sessions: any[];
  }>({
    existing_sessions: [],
    deleted_sessions: [],
  });

  const [sessionOptionData, setSessionOptionData] = useState<any[]>([]);
  const [parentFilter, setParentFilter] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [sessionSelected, setSessionSelected] = useState<{
    current: boolean;
    options: boolean;
  }>({
    current: false,
    options: false,
  });
  const [optionCheckBox, setOptionCheckBox] = useState<{
    [key: string]: boolean;
  }>({});

  const [addOrEditFolderSession] = useMutation<
    addOrEditFolderSessions,
    addOrEditFolderSessionsVariables
  >(ADD_OR_EDIT_FOLDER_SESSIONS, {
    onCompleted: ({ addOrEditFolderSessions }) => {
      if (addOrEditFolderSessions) {
        history.push(`/folders`);
      }
    },
  });

  const [deleteFolderMutation] = useMutation<
    deleteOneFolder,
    deleteOneFolderVariables
  >(DELETE_ONE_FOLDER, {
    onCompleted: ({ deleteOneFolder }) => {
      if (deleteOneFolder) {
        history.push(`/folders`);
      }
    },
  });

  const [filterState, setFilterState] = useState({
    parent_label: [],
  });

  useEffect(() => {
    if (sessionData && sessionData.length) {
      setChildSessions({
        ...childSessions,
        existing_sessions: sessionData.map((el) => ({ ...el, checked: false })),
      });
    }
    if (sessionOptions && sessionOptions.length) {
      setSessionOptionData(
        sessionOptions.map((el) => ({ ...el, checked: false }))
      );
      const intial_options = sessionOptions.reduce((acc, el) => {
        if (el && el.id) {
          const id = el.id.toString();
          acc[id] = false;
        }
        return acc;
      }, {});
      setOptionCheckBox(intial_options);
    }
  }, [sessionData, sessionOptions]);

  const handleSubmit = () => {
    addOrEditFolderSession({
      variables: {
        folder_id: folderId,
        existing_sessions: childSessions.existing_sessions.map((el) => {
          return { id: Number(el.id), title: el.title };
        }),
        deleted_sessions: childSessions.deleted_sessions.map((el) => {
          return { id: Number(el.id), title: el.title };
        }),
      },
    });
  };

  const handleDelete = () => {
    deleteFolderMutation({
      variables: {
        where: {
          id: folderId,
        },
      },
    });
  };

  const filterByType = (event) => {
    const values: string[] = event ? event.map((el) => el.value) : [];
    setParentFilter(values);
  };

  const handleCheckboxChange = (event, type, index = 0) => {
    const id = event.target.id;
    if (type === "New") {
      setOptionCheckBox({
        ...optionCheckBox,
        [id]: !optionCheckBox[id],
      });
    }
    if (type === "Existing") {
      const new_existing = childSessions.existing_sessions.map((el) => ({
        ...el,
        checked: true,
      }));
      setChildSessions({
        existing_sessions: new_existing,
        deleted_sessions: childSessions.deleted_sessions,
      });
    }
  };

  const selectAll = (status = true, type = "New") => {
    if (type === "New") {
      const options = filtered_sessions()?.reduce((acc, el) => {
        const id = el.id.toString();
        acc[id] = status;
        return acc;
      }, {});

      setOptionCheckBox({
        ...optionCheckBox,
        ...options,
      });
      setSessionSelected({
        ...sessionSelected,
        options: status,
      });
    }
    if (type === "Existing") {
      const new_existing = childSessions.existing_sessions.map((el) => ({
        ...el,
        checked: status,
      }));

      setChildSessions({
        existing_sessions: new_existing,
        deleted_sessions: childSessions.deleted_sessions,
      });

      setSessionSelected({
        ...sessionSelected,
        current: status,
      });
    }
  };

  const selectedCount = (type: string) => {
    let count = 0;
    if (type === "New") {
      count = Object.values(optionCheckBox).filter((el) => el === true).length;
    }
    if (type === "Existing") {
      count = childSessions.existing_sessions.filter(
        (el) => el.checked === true
      ).length;
    }

    if (count === 0) {
      return "";
    }
    return `${count} sessions selected `;
  };

  const filtered_sessions = () => {
    if (!parentFilter.length && !searchText) {
      return sessionOptionData;
    }

    const filtered = sessionOptionData?.filter((el) => {
      if (el?.parent_label) {
        if (parentFilter.length) {
          return parentFilter.includes(el.parent_label);
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
    let searchTextData = new RegExp(searchText, "i");

    return filtered.filter((el) => {
      return el.title.match(searchTextData);
    });
  };

  const session_objects = (type = "Existing") => {
    if (type === "Existing") {
      return childSessions.existing_sessions.reduce((acc, el) => {
        acc[el.id] = true;
        return acc;
      }, {});
    }
    if (type === "Deleted") {
      return childSessions.deleted_sessions.reduce((acc, el) => {
        acc[el.id] = true;
        return acc;
      }, {});
    }
  };

  const session_option_object = () => {
    return sessionOptionData.reduce((acc, el) => {
      acc[el.id] = el;
      return acc;
    }, {});
  };

  const addSessionToBucket = (
    existing_session_list,
    existing_sessions,
    session_option_objects
  ) => {
    let session_datas: any = [];

    for (const id in optionCheckBox) {
      if (!existing_session_list[id] && optionCheckBox[id]) {
        existing_sessions.push({
          id: Number(id),
          title: session_option_objects[id].title,
          __typename: "Session",
          parent_label: session_option_objects[id].parent_label,
          checked: false,
        });
      } else {
        const data = session_option_objects[id];
        if (data) {
          session_datas.push(data);
        }
      }
    }

    setChildSessions({
      ...childSessions,
      existing_sessions: existing_sessions,
    });
    setSessionOptionData(session_datas);
  };

  const deleteSessionFromBucket = (
    deleted_session_list,
    existing_sessions,
    session_option_objects
  ) => {
    const selected = existing_sessions.filter((el) => el.checked === true);
    const unselected = existing_sessions.filter((el) => el.checked === false);

    const session_datas = sessionOptionData.slice();
    const deleted_sessions = sessionOptionData.slice();

    for (const deleted_session of selected) {
      if (!deleted_session_list[deleted_session.id]) {
        deleted_sessions.push(deleted_session);
      }
      if (!session_option_objects[deleted_session.id]) {
        session_datas.push(deleted_session);
      }
    }
    setChildSessions({
      ...childSessions,
      existing_sessions: unselected,
      deleted_sessions: deleted_sessions,
    });
    setSessionOptionData(session_datas);
  };

  const handleSessionData = (type = "Add") => {
    const existing_session_list = session_objects("Existing");
    const deleted_session_list = session_objects("Deleted");
    const session_option_objects = session_option_object();

    let existing_sessions = childSessions.existing_sessions.slice();

    if (type === "Add") {
      addSessionToBucket(
        existing_session_list,
        existing_sessions,
        session_option_objects
      );
    } else {
      deleteSessionFromBucket(
        deleted_session_list,
        existing_sessions,
        session_option_objects
      );
    }
    selectAll(false);
    setSessionSelected({
      current: false,
      options: false,
    });
    setParentFilter([]);
    setSearchText("");
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <Container>
      <FlexCol>
        <Section>
          <FlexRow>
            <h1>Current Sessions </h1>
            <CustomButton onClick={() => handleSessionData("Delete")}>
              Remove Selected
            </CustomButton>
          </FlexRow>
          <ScrollableContainer>
            <InlineLabel>
              <input
                type="checkbox"
                checked={sessionSelected.current}
                onChange={(e) =>
                  selectAll(!sessionSelected.current, "Existing")
                }
              />
              <p>Select All</p>
            </InlineLabel>
            <FormatedRow>{selectedCount("Existing")}</FormatedRow>

            {childSessions.existing_sessions.map((el, i) => {
              return (
                <InlineLabel key={i}>
                  <input
                    key={i}
                    type="checkbox"
                    value={el.title}
                    id={`${el.id}`}
                    checked={el["checked"]}
                    onChange={(e) => handleCheckboxChange(e, "Existing", i)}
                  />
                  <p>{el!.title}</p>
                </InlineLabel>
              );
            })}
          </ScrollableContainer>
        </Section>

        <Section>
          <FlexRow>
            <h1>Available Session Options </h1>
            <CustomButton onClick={() => handleSessionData()}>
              Add Selected
            </CustomButton>
          </FlexRow>
          <Selector
            className="selector"
            name="parent_label"
            isMulti
            placeholder="Parent Filter"
            value={parentFilter.map((el) => {
              return { value: el, label: el };
            })}
            onChange={filterByType}
            options={parent_options(sessionOptionData)}
          />
          <p>Search : </p>
          <InputForm
            type="text"
            name="searchText"
            value={searchText}
            onChange={handleSearchText}
          />

          <InlineLabel>
            <input
              type="checkbox"
              checked={sessionSelected.options}
              onChange={(e) => selectAll(!sessionSelected.options)}
            />
            <p>Select All</p>
          </InlineLabel>

          <FormatedRow>{selectedCount("New")}</FormatedRow>

          <ScrollableContainer>
            {filtered_sessions()?.map((el, i) => {
              return (
                <InlineLabel key={i}>
                  <input
                    key={i}
                    type="checkbox"
                    value={el!.title}
                    id={`${el!.id}`}
                    checked={optionCheckBox[el!.id]}
                    onChange={(e) => handleCheckboxChange(e, "New")}
                  />
                  <p>{el!.title}</p>
                </InlineLabel>
              );
            })}
          </ScrollableContainer>
        </Section>
      </FlexCol>
      <FlexCol>
        <SAVE_BUTTON onClick={handleSubmit}> Save </SAVE_BUTTON>
        <SAVE_BUTTON background={"#afafaf"} onClick={handleDelete}>
          Delete
        </SAVE_BUTTON>
      </FlexCol>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: auto;
`;

const InputForm = styled.input`
  width: 259px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const FlexCol = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const InlineLabel = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px 5px;
  }
  button {
    margin-left: 11px;
    align-self: center;
  }
`;

const Section = styled.section`
  padding: 30px;
`;

const FormatedRow = styled.p`
  margin: 15px 0;
  font-weight: 900;
`;
const ScrollableContainer = styled.section`
  height: 400px;
  width: 400px;

  overflow: scroll;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Selector = styled(Select).attrs({ activeClassName: "selector" })`
  width: 200px;
`;

const CustomButton = styled.button`
  font-family: Open Sans, sans-serif;
  color: #0076ff;
  border: none;
  background-color: inherit;
  font-size: 16px;
  padding: 8px 15px;
  margin: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
`;
