import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../utils/theme";

export interface ButtonProps {
  width?: number;
  height?: number;
  fontSize?: number;
  color?: string;
  backgroundColor?: string;
  background?: string;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => props.color || theme.MEDIUM_GREY_COLOR};
  max-width: 260px;
  margin-top: 32px;
  height: 38px;
  height: ${(props) => props.height || 30}px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.backgroundColor || theme.BUTTON_BACKGROUND_COLOR};
  border: none;
  font-size: ${(props) => props.fontSize || 16}px;
  padding: 8px 15px;
  margin: auto;
  align-self: center;
  & > p {
    margin: auto;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

export const ADD_BUTTON = styled(Link)`
  margin-top: -45px;
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

export const ADD_BUTTON_2 = styled.button`
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
export const SAVE_BUTTON = styled.button<ButtonProps>`
  display: inline-block;
  align-items: center;
  border: none;
  width: 186px;
  line-height: 43px;
  color: #ffffff;
  border-radius: 23.8px;
  margin: 10px;
  background: ${(props) =>
    props.background ? props.background : theme.PRIMARY_COLOR};
  font-weight: 500;
  letter-spacing: -0.1px;
  text-decoration: none;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

export const DELETE_BUTTON = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  border: none;
  height: 25px;
  border-radius: 23.8px;
  background-color: #212121;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-decoration: none;
  margin-top: 20px;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
  p {
    color: #ffffff;
    margin-left: 5px;
  }
`;

export const BACK_BUTTON = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.1px;
  }
`;

export const BUTTON_END = styled(Link)`
  align-self: flex-end;
  text-decoration: none;
  border: 1px solid black;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #0089b7;
  color: #fff;
  border: none;
  border-radius: 4px;
`;

export const BACK_BUTTON_HISTORY = styled.p`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.1px;
  }
`;
