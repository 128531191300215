import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { EDIT_ONE_SESSION } from "../../../apollo/mutations";
import { GET_SESSION } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { SessionForm } from "./SessionForm";

export const EditSession = (props: any) => {
  const sessionId = Number(props.match.params.sessionId);

  const { data, loading, error } = useQuery(GET_SESSION, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        id: sessionId,
      },
      fetchPolicy: "network-only",
    },
  });

  if (loading) return <Loading />;

  return (
    <SessionForm
      session={data?.session}
      sessionId={sessionId}
      catMutation={EDIT_ONE_SESSION}
    />
  );
};
