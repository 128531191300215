import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
// import { UPDATE_TOPIC_ORDERS } from "../../../apollo/mutations";
import { GET_SESSIONS } from "../../../apollo/queries";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { parent_options } from "../../../utils/functions";
import { theme } from "../../../utils/theme";
import {
  ACTIVE_STATE_TEXT,
  Body,
  BUTTON_END,
  Col,
  RowSpanMax,
  EDIT_ICON_COL,
  Head,
  Row,
} from "../../UI";

export const Sessions = () => {
  let history = useHistory();

  const {
    loading: sessionLoading,
    error: sessionError,
    data,
  } = useQuery(GET_SESSIONS, {
    fetchPolicy: "network-only",
    onCompleted: ({ sessions }) => {
      if (data && data.sessions.length) {
        setSessionData(data.sessions);
      }
    },
  });
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [sortState, setSortState] = useState({ col: "", direction: false });
  const [filterState, setFilterState] = useState<any>({
    parent_label: [],
    is_active: [],
    is_premium: [],
  });
  const [searchText, setSearchText] = useState<string>("");

  if (sessionLoading) return <p>loading ...</p>;
  if (sessionError) return `sessionError! ${sessionError}`;

  const handleEdit = (id) => {
    history.push(`/session/edit/${id}`);
  };

  const filterByType = (type, data) => {
    const values = data ? data.map((el) => el.value) : [];
    setFilterState({
      ...filterState,
      [type]: values,
    });
  };

  const sortByName = (type) => {
    setSortState({ col: type, direction: !sortState.direction });
    setSessionData(
      sessionData.slice().sort((a, b) => {
        if (a[type] > b[type]) {
          if (sortState.direction) {
            return 1;
          } else {
            return -1;
          }
        } else if (a[type] < b[type]) {
          if (sortState.direction) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      })
    );
  };

  const filteredSessions = () => {
    let filtered = sessionData.filter(
      (el: {
        parent_label?: string;
        is_premium?: boolean;
        is_active?: boolean;
      }) => {
        return (
          (filterState.parent_label.length === 0 ||
            filterState.parent_label.includes(el?.parent_label)) &&
          (filterState.is_premium.length === 0 ||
            filterState.is_premium.includes(el?.is_premium)) &&
          (filterState.is_active.length === 0 ||
            filterState.is_active.includes(el?.is_active))
        );
      }
    );

    let searchTextData = new RegExp(searchText, "i");

    return filtered.filter((el) => {
      return el.title.match(searchTextData);
    });
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <Container className="container">
      <BUTTON_END to="session/add"> Add New </BUTTON_END>

      <Table>
        <Head>
          {/* <RowSpanMax>
            <Col>
              <CenterAlignDiv>
                <p>Search: </p>

                <InputForm
                  type="text"
                  name="searchText"
                  value={searchText}
                  onChange={handleSearchText}
                />
              </CenterAlignDiv>
            </Col>
          </RowSpanMax> */}
          <Row>
            <Col>
              <CenterAlignDiv>
                <p>
                  <b>Search: </b>
                </p>

                <InputForm
                  type="text"
                  name="searchText"
                  value={searchText}
                  onChange={handleSearchText}
                />
              </CenterAlignDiv>
            </Col>
            <Col>
              <Selector
                className="selector"
                name="parent_label"
                isMulti
                placeholder="Parent Filter"
                onChange={(e) => filterByType("parent_label", e)}
                options={parent_options(data.sessions)}
              />
            </Col>
            <Col>
              <Selector
                className="selector"
                name="is_active"
                placeholder="Active Filter"
                onChange={(e) => filterByType("is_active", e)}
                isMulti
                options={[
                  { value: true, label: "Active" },
                  {
                    value: false,
                    label: "Not Active",
                  },
                ]}
              />
            </Col>
            <Col>
              <Selector
                className="selector"
                name="is_premium"
                placeholder="Premium Filter"
                onChange={(e) => filterByType("is_premium", e)}
                isMulti
                options={[
                  { value: true, label: "Premium" },
                  {
                    value: false,
                    label: "Free",
                  },
                ]}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <SortSpan
                itemID="title"
                itemProp={`${sortState.col}`}
                onClick={() => sortByName("title")}
              >
                Title
              </SortSpan>
            </Col>
            <Col>
              <SortSpan
                itemID="parent_label"
                itemProp={`${sortState.col}`}
                onClick={() => sortByName("parent_label")}
              >
                Parent
              </SortSpan>
            </Col>
            <Col>
              <SortSpan
                itemID="is_active"
                itemProp={`${sortState.col}`}
                onClick={() => sortByName("is_active")}
              >
                Is Active{" "}
              </SortSpan>
            </Col>
            <Col>
              <SortSpan
                itemID="is_premium"
                itemProp={`${sortState.col}`}
                onClick={() => sortByName("is_premium")}
              >
                Free/Premium
              </SortSpan>
            </Col>
            <Col>Edit</Col>
          </Row>
        </Head>
        <Body>
          {filteredSessions().map((session, i) => {
            return (
              <Row key={i}>
                <Col>
                  <p>{session["title"]}</p>
                </Col>

                <Col>
                  <p>{session["parent_label"]}</p>
                </Col>
                <Col>
                  <ACTIVE_STATE_TEXT active={session["is_active"]}>
                    {session["is_active"] ? "Active" : "Not Active"}
                  </ACTIVE_STATE_TEXT>
                </Col>
                <Col>
                  <p>{session["is_premium"] ? "Premium" : "Free"}</p>
                </Col>

                <EDIT_ICON_COL
                  className="icon"
                  onClick={() => handleEdit(session["id"])}
                >
                  <EditIcon />
                </EDIT_ICON_COL>
              </Row>
            );
          })}
        </Body>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
`;

const SortSpan = styled.span`
  font-weight: ${(props) => (props.itemProp === props.itemID ? "900" : "500")};
  cursor: pointer;
`;

const InputForm = styled.input`
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
  height: 30px;
`;

const CenterAlignDiv = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${theme.PRIMARY_FONT};
  p {
    margin-right: 10px;
  }
`;

export const Table = styled.table`
  padding: 25px 15px;
  margin-top: 25px;
  align-self: center;
  text-align: center;
  display: grid;
  border-radius: 5px;
  background-color: #fff;
  border-collapse: unset;
  overflow: auto;
  grid-template-columns: repeat(5, 300px);
`;

const Selector = styled(Select).attrs({ activeClassName: "selector" })`
  width: 200px;
`;
