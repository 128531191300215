import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DELETE_ONE_PREMIUM_USER } from "../../../apollo/mutations";
import { GET_ALL_PREMIUM_USERS } from "../../../apollo/queries";
import { ImageUpload } from "../../Segments/ImageUpload";
import { Button, Err, DeleteMessage, TEXTAREA } from "../../UI";

export const PremiumUser = (props) => {
  const { premium_user } = props;
  let history = useHistory();

  const [deleteOnePremiumUser] = useMutation(DELETE_ONE_PREMIUM_USER, {
    onCompleted({ updateOnePromoSection }) {
      DeleteMessage();
    },
    refetchQueries: [{ query: GET_ALL_PREMIUM_USERS }],
    awaitRefetchQueries: true,
  });

  const handleDelete = () => {
    deleteOnePremiumUser({
      variables: {
        email: premium_user.email,
      },
    });
  };

  return (
    <FlexRow>
      <div>{premium_user.email}</div>
      <Button
        backgroundColor={"#d0021b"}
        color={"#fff"}
        type="button"
        onClick={handleDelete}
      >
        delete
      </Button>
    </FlexRow>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eceeef;
  padding: 5px 30px;

  & > * {
    margin: 0 5px;
    align-self: center;
  }
  :hover {
    border-bottom: 1px solid #0089b7;
    background: rgba(0, 0, 0, 0.03);
    padding: 5px 30px;
  }
`;
