import { gql } from "@apollo/client";
export const UPLOAD_IMAGE = gql`
  mutation uploadImage($file: Upload!) {
    uploadImage(file: $file) {
      url
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument($file: Upload!) {
    uploadDocument(file: $file) {
      url
    }
  }
`;
export const UPLOAD_AUDIO = gql`
  mutation uploadAudio($file: Upload!) {
    uploadAudio(file: $file) {
      url
    }
  }
`;

// topic
export const CREATE_ONE_TOPIC = gql`
  mutation createOneTopic($data: TopicCreateInput!) {
    createOneTopic(data: $data) {
      title
      image_url
      is_active
    }
  }
`;

export const EDIT_ONE_TOPIC = gql`
  mutation updateOneTopic(
    $data: TopicUpdateInput!
    $where: TopicWhereUniqueInput!
  ) {
    updateOneTopic(data: $data, where: $where) {
      title
      image_url
      is_active
    }
  }
`;

export const DELETE_ONE_TOPIC = gql`
  mutation deleteOneTopic($where: TopicWhereUniqueInput!) {
    deleteOneTopic(where: $where) {
      id
      title
      image_url

      is_active
    }
  }
`;

// updateTopicOrder

export const UPDATE_TOPIC_ORDERS = gql`
  mutation updateTopicOrder($data: [Int!]!) {
    updateTopicOrder(topicIds: $data)
  }
`;

// folder

export const CREATE_ONE_FOLDER = gql`
  mutation createOneFolder($data: FolderCreateInput!) {
    createOneFolder(data: $data) {
      id
      name
      sessions {
        id
        title
      }
      sub_folders {
        id
        name
      }
    }
  }
`;

export const EDIT_ONE_FOLDER = gql`
  mutation updateOneFolder(
    $data: FolderUpdateInput!
    $where: FolderWhereUniqueInput!
  ) {
    updateOneFolder(data: $data, where: $where) {
      id
      name
      sessions {
        id
        title
      }
      parent_folder {
        id
        name
      }

      sub_folders {
        id
        name
      }
    }
  }
`;

export const DELETE_ONE_FOLDER = gql`
  mutation deleteOneFolder($where: FolderWhereUniqueInput!) {
    deleteOneFolder(where: $where) {
      id
      name
    }
  }
`;

export const CREATE_ONE_SESSION = gql`
  mutation createOneSession($data: SessionCreateInput!) {
    createOneSession(data: $data) {
      title
      image_url
      is_active
    }
  }
`;

export const EDIT_ONE_SESSION = gql`
  mutation updateOneSession(
    $data: SessionUpdateInput!
    $where: SessionWhereUniqueInput!
  ) {
    updateOneSession(data: $data, where: $where) {
      title
      image_url
      is_active
    }
  }
`;

export const DELETE_ONE_SESSION = gql`
  mutation deleteOneSession($where: SessionWhereUniqueInput!) {
    deleteOneSession(where: $where) {
      id
      title
      image_url
      is_active
    }
  }
`;

// topic
export const CREATE_ONE_SERIES = gql`
  mutation createOneSeries($data: SeriesCreateInput!) {
    createOneSeries(data: $data) {
      title
      image_url
      is_active
    }
  }
`;

export const EDIT_ONE_SERIES = gql`
  mutation updateOneSeries(
    $data: SeriesUpdateInput!
    $where: SeriesWhereUniqueInput!
  ) {
    updateOneSeries(data: $data, where: $where) {
      title
      image_url
      is_active
    }
  }
`;

export const DELETE_ONE_SERIES = gql`
  mutation deleteOneSeries($where: SeriesWhereUniqueInput!) {
    deleteOneSeries(where: $where) {
      id
      title
      image_url
      is_active
    }
  }
`;

export const UPDATE_CUSTOM_SESSION = gql`
  mutation updateCustomSession(
    $answer_ids: [Int!]!
    $session_id: Int!
    $custom_session_id: Int
  ) {
    updateCustomSession(
      answer_ids: $answer_ids
      session_id: $session_id
      custom_session_id: $custom_session_id
    ) {
      id
      session {
        id
        title
      }
    }
  }
`;

export const UPDATE_CUSTOM_QUESTION_ANSWERS = gql`
  mutation updateQuestionAnswers(
    $answers: [AnswerCMSType]
    $deleted_answers: [AnswerCMSType]
    $question: String!
    $question_type: String!
    $question_id: Int!
  ) {
    updateQuestionAnswers(
      deleted_answers: $deleted_answers
      answers: $answers
      question: $question
      question_type: $question_type
      question_id: $question_id
    ) {
      id
      type
      question
      answers {
        id
        question_id
        answer
      }
    }
  }
`;

export const UPDATE_ONE_PROMO_SECTION = gql`
  mutation updateOnePromoSection(
    $data: PromoSectionUpdateInput!
    $where: PromoSectionWhereUniqueInput!
  ) {
    updateOnePromoSection(data: $data, where: $where) {
      title
      description
      button_text
      external_link
      image_url
    }
  }
`;

export const ADD_ONE_PREMIUM_USER = gql`
  mutation addPremiumUser($email: String!) {
    addPremiumUser(email: $email) {
      email
    }
  }
`;

export const DELETE_ONE_PREMIUM_USER = gql`
  mutation deletePremiumUser($email: String!) {
    deletePremiumUser(email: $email) {
      email
    }
  }
`;

export const DELETE_ONE_USER = gql`
  mutation deleteUserCMS($user_id: Int!) {
    deleteUserCMS(user_id: $user_id) {
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPasswordViaCode(
    $email: String!
    $password: String!
    $code: String!
  ) {
    resetPasswordViaCode(email: $email, password: $password, code: $code)
  }
`;

export const UPDATE_CHILD_SORT_ORDER = gql`
  mutation updateTopicChildOrder(
    $topic_id: Int!
    $child_objects: [CatgoryChildUType!]!
  ) {
    updateTopicChildOrder(topic_id: $topic_id, child_objects: $child_objects)
  }
`;

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      id
      session_token
    }
  }
`;

export const ADD_OR_EDIT_FOLDER_SESSIONS = gql`
  mutation addOrEditFolderSessions(
    $folder_id: Int!
    $existing_sessions: [SessionMinType]
    $deleted_sessions: [SessionMinType]
  ) {
    addOrEditFolderSessions(
      folder_id: $folder_id
      existing_sessions: $existing_sessions
      deleted_sessions: $deleted_sessions
    ) {
      id
    }
  }
`;

export const UPDATE_SESSION_SORT_ORDER = gql`
  mutation updateSeriesSessionOrder(
    $series_id: Int!
    $sessions: [SeriesSessionChildType!]!
  ) {
    updateSeriesSessionOrder(series_id: $series_id, sessions: $sessions)
  }
`;
