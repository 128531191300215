import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { restAPI } from "../../apollo";
import { UPLOAD_DOCUMENT } from "../../apollo/mutations";
import { ReactComponent as APkFile } from "../../images/apk.svg";
import { ReactComponent as Success } from "../../images/success.svg";
import { ReactComponent as ZipFile } from "../../images/zip.svg";
import { Loading } from "../UI";
export const FileUpload = ({ fileType }) => {
  const [uploaded, setUploadStatus] = useState(false);
  const [src, setSrc] = useState("");
  const name = "fileUpload";

  const allowFiles = ".csv";
  let width = 275,
    height = 275;

  const uploadFile = async (formData) => {
    await restAPI.post(`/uploads/premiumUsers`, formData);
  };
  const fileHandler = async ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (file) {
      setUploadStatus(true);
      const formData = new FormData();
      formData.append("data", file);
      await uploadFile(formData);
    } else {
      return <Loading />;
    }
  };
  const uploadText = "or Upload via CSV ";

  return (
    <div>
      <UploadContainer className="image-details">
        <Label htmlFor={name}>
          <p>{uploadText}</p>
        </Label>
        <input
          id={name}
          type="file"
          accept={allowFiles}
          onChange={fileHandler}
          style={{ visibility: "hidden" }}
        />
      </UploadContainer>
    </div>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
  width?: number;
  height?: number;
}

const UploadContainer = styled.div<UploadedProps>``;

const NewUploadText = styled.p<UploadedProps>`
  display: ${(props) => (props.uploaded ? "none" : "block")};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #212121;
`;

const ImageContainer = styled.div<UploadedProps>`
  border-radius: 4px;
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .image-details {
    display: ${(props) => (props.uploaded ? "none" : "flex")};
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
  }
  &:hover {
    color: #ffffff;
    .image-details {
      display: flex;
      position: absolute;
      z-index: 1;
      background: ${(props) =>
        props.uploaded ? "rgba(33, 33, 33, 0.5)" : "inherit"};
    }
    .image-details label {
      background: ${(props) => (props.uploaded ? "none" : "#212121")};
    }
  }
`;

const Uploaded = styled.div<UploadedProps>`
  position: relative;
  width: ${(props) => (props.uploaded ? props.width : 0)}px;
  height: ${(props) => (props.uploaded ? props.height : 0)}px;
  display: ${(props) => (props.uploaded ? "flex" : "none")};
`;

const Label = styled.label`
  margin: 10px auto;
  border-radius: 23.8px;
  min-width: 129px;
  font-weight: bold;
  letter-spacing: -0.1px;
  color: #ffffff;
  background: #212121;
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;
