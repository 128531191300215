import styled from "styled-components";

export const Table = styled.table`
  margin: 30px 44px;
  text-align: left;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
`;

export const Head = styled.thead`
  display: contents;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.09px;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  max-width: 0;
`;

export const Body = styled.tbody`
  display: contents;
  font-size: 15px;
  font-weight: 500;
  padding: 15px;
  color: #505050;
  max-width: 0;

  letter-spacing: -0.09px;
  & > tr {
    padding: 14px;
  }
`;

export const Row = styled.tr`
  display: contents;
  padding: 15px;
  max-width: 0;
`;

export const Col = styled.td`
  display: flex;
  padding: 15px 0;
  text-align: left;
  width: 100%;
  min-width: 1px;
  border-bottom: solid 1.5px #e7e7e7;
  & > * {
    display: block;
    align-self: center;
  }
  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 40px;
  }
`;

export const RowSpanMax = styled.tr`
  grid-column: 1 / -1;
`;

export const EDIT_ICON_COL = styled.td`
  display: flex;
  padding: 15px 0;
  border-bottom: solid 1.5px #e7e7e7;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
  & > * {
    display: flex;
    align-self: center;
  }
`;
