import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UPLOAD_AUDIO } from "../../apollo/mutations";
import { theme } from "../../utils/theme";
import { Loading } from "../UI";

export const AudioUpload = (props) => {
  const formik = props.formik;

  const name = props.header ? props.header : "main_url";
  const audioTitle = props.audioTitle ? props.audioTitle : "Audio File";
  const [src, setSrc] = useState("");
  const [fallback, setFallback] = useState(false);
  const reloadSrc = (e) => {
    if (fallback) {
      e.target.src = src;
    } else {
      e.target.src = src;
      setFallback(true);
    }
  };
  const [audioUploadMutation, { loading, error }] = useMutation(UPLOAD_AUDIO, {
    onCompleted({ uploadAudio }) {
      formik.setFieldValue(name, uploadAudio.url);
    },
  });

  useEffect(() => {
    setSrc(formik.values.audioUrl);
  }, [props]);

  if (error) {
    console.log(error);
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const fileHandler = ({
    target: {
      files: [file],
    },
  }: any) => {
    if (!file) {
      return <Loading />;
    }
    console.log(file);

    if (file) {
      audioUploadMutation({ variables: { file } });
    }
  };
  const progressBar = (e) => {
    const percent = (e.loaded / e.total) * 100;
    console.log(percent);
  };

  const audioHandler = async ({ target: { duration } }: any) => {
    if (duration) {
      if (duration === Infinity) {
        await new Promise((r) => setTimeout(r, 1000));
      }
      console.log(duration);

      formik.setFieldValue("length", Math.floor(duration) || 0);
    } else {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }
  };

  return (
    <Container>
      <div>
        <h3>{audioTitle}</h3>
        <div>
          <AudioContainer
            controls
            autoPlay={true}
            onLoadedMetadata={audioHandler}
            onProgress={progressBar}
            onDurationChangeCapture={audioHandler}
            key={formik.values[name]}
            src={formik.values[name]}
          ></AudioContainer>
          <UploadContainer className="image-details">
            <AudioUploadLabel htmlFor="audioFiles">
              <p>Audio Upload</p>
            </AudioUploadLabel>
            <input
              id="audioFiles"
              type="file"
              accept="audio/*"
              onChange={fileHandler}
              style={{ visibility: "hidden" }}
            />
          </UploadContainer>
        </div>
      </div>
    </Container>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
}

const Container = styled.div`
  display: flex;
  min-width: 250px;
  min-height: 250px;
`;

const AudioContainer = styled.audio`
  max-width: 350px;
`;

const LoadingContainer = styled.div`
  width: 250px;
  height: 250px;
`;
const UploadContainer = styled.div<UploadedProps>``;

const AudioUploadLabel = styled.label`
  align-items: center;
  border: none;
  width: 150px;
  line-height: 43px;
  border-radius: 23.8px;
  background-color: ${theme.PRIMARY_COLOR};
  font-weight: 500;
  display: block;
  p {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
  }
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
