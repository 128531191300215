import { gql } from "@apollo/client";
import { TopicFragment } from "../fragments";

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const GET_TOPICS = gql`
  query getTopics {
    topics(orderBy: { sort_order: asc }) {
      ...Topic
    }
  }
  ${TopicFragment}
`;

export const GET_TOPIC = gql`
  query getTopic($data: TopicWhereUniqueInput!) {
    topic(where: $data) {
      id
      title
      image_url
      description
      sessions {
        id
        title
        is_active
        is_recommended
        length
        sort_order
      }

      series {
        id
        title
        is_active
      }
      is_active
      image_url
    }
  }
`;

export const GET_FOLDERS = gql`
  query getFolders {
    folders(orderBy: { name: asc }) {
      id
      name
      sessions {
        id
        title
      }
      sub_folders {
        id
        name
      }
    }
  }
`;

export const GET_FOLDER = gql`
  query getFolder($data: FolderWhereUniqueInput!) {
    folder(where: $data) {
      id
      name
      sub_folders {
        id
        name
      }
      sessions {
        id
        title
        parent_label
      }
      parent_folder {
        id
        name
      }
    }
  }
`;

export const GET_SESSIONS = gql`
  query getSessions {
    sessions(orderBy: { title: asc }) {
      id
      title
      description
      is_active
      parent
      parent_label
      is_premium
    }
  }
`;

export const GET_SESSION = gql`
  query getSession($data: SessionWhereUniqueInput!) {
    session(where: $data) {
      id
      title
      description
      is_premium
      is_active
      series_id
      topic_id
      image_url
      bg_image_url
      main_url
      bg_url
      parent
      parent_id
      parent_label
      length
      Series {
        id
        title
        data_type
      }
      Topic {
        id
        title
        data_type
      }
    }
  }
`;

export const GET_SERIES = gql`
  query fetchOneSeries($series_id: Int!) {
    fetchOneSeries(series_id: $series_id) {
      id
      title
      description
      days_description
      is_active
      image_url
      sessions {
        id
        title
        sort_order
      }
      topic_id
      Topic {
        id
        title
      }
    }
  }
`;

export const GET_ALL_SERIES = gql`
  query getSeries {
    series(orderBy: { title: asc }) {
      id
      title
      description
      is_active
      Topic {
        id
        title
      }
      topic_id
    }
  }
`;

export const GET_ALL_CUSTOM_SESSIONS = gql`
  query fetchCMSCustomSessions {
    fetchCMSCustomSessions {
      session_id
      session_title
      type
      row_id
      row_values
      answer_ids
    }
  }
`;

export const GET_ALL_QUESTIONS = gql`
  query fetchAllQuestions {
    fetchAllQuestions {
      id
      type
      question
      answers {
        id
        question_id
        is_premium
        answer
      }
    }
  }
`;

export const GET_ALL_PROMOTIONS = gql`
  query promoSections {
    promoSections(orderBy: { id: asc }) {
      id
      section
      title
      description
      button_text
      external_link
      image_url
    }
  }
`;

export const GET_ALL_ANSWERS = gql`
  query customAnswers {
    customAnswers(orderBy: { question_id: asc }) {
      id
      answer
    }
  }
`;

export const GET_ALL_PREMIUM_USERS = gql`
  query premiumUsers {
    premiumUsers(orderBy: { created_at: asc }) {
      email
    }
  }
`;

export const GET_ALL_USERS = gql`
  query Users {
    users {
      id
      email
      login_type
    }
  }
`;

export const FETCH_USER_VIA_CODE = gql`
  query fetchUserViaCode($code: String!) {
    fetchUserViaCode(code: $code) {
      id
      email
    }
  }
`;

export const GET_ALL_PARENT = gql`
  query fetchAllParent {
    fetchAllParent
  }
`;

export const GET_FOLDER_SESSION_OPTIONS = gql`
  query fetchFolderSessionOptions($folder_id: Int!) {
    fetchFolderSessionOptions(folder_id: $folder_id) {
      id
      title
      parent_label
    }
  }
`;
