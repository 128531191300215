import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UPDATE_CUSTOM_QUESTION_ANSWERS } from "../../../apollo/mutations";
import { theme } from "../../../utils/theme";
import { Button, SuccessMessage } from "../../UI";
import { CustomAnswer } from "./CustomAnswer";

export const CustomQuestion = (props) => {
  const { question } = props;

  const [answers, setAnswers] = useState<any[]>([]);
  const [deletedAnswers, setDeletedAnswers] = useState<any[]>([]);

  useEffect(() => {
    setAnswers(question.answers);
  }, [question]);

  const addNewAnswer = ({ formik }) => {
    setAnswers([
      ...answers,
      { answer: "", question_id: question.id, is_premium: false },
    ]);
  };

  const handleAnswerChange = (value, name, index) => {
    setAnswers(
      answers.map((el, i) => {
        return i === index ? { ...el, [name]: value } : el;
      })
    );
  };
  const handleAnswerDelete = (index) => {
    const deleted_el = answers[index];
    if (deleted_el.id) {
      setDeletedAnswers([...deletedAnswers, deleted_el]);
    }

    console.log("deletedAnswers", deleted_el, deletedAnswers);

    setAnswers(
      answers.filter((el, i) => {
        return i !== index;
      })
    );
  };

  const [updateQuestionAnswers] = useMutation(UPDATE_CUSTOM_QUESTION_ANSWERS, {
    onCompleted({ updateQuestionAnswers }) {
      SuccessMessage();
    },
  });
  const handleSubmit = (values) => {
    const final_answers = answers.map((el) => {
      return {
        id: el.id || undefined,
        question_id: el.question_id,
        answer: el.answer,
        is_premium: el.is_premium,
      };
    });

    const deleted_answers = deletedAnswers.map((el) => {
      return {
        id: el.id,
        question_id: el.question_id,
        answer: el.answer,
        is_premium: el.is_premium,
      };
    });

    updateQuestionAnswers({
      variables: {
        answers: final_answers,
        deleted_answers: deleted_answers,
        question: values.question,
        question_id: values.question_id,
        question_type: values.type,
      },
    });
  };

  return (
    <Container>
      <Formik
        initialValues={{
          question_id: question ? question.id : "",
          type: question ? question.type : "",
          question: question ? question.question : "",
          answers: question ? question.answers : [],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            <h3>{question.type}</h3>
            <FlexRow>
              <InlineLabel>
                <p>Question : </p>
                <InputForm
                  type="text"
                  placeholder="question"
                  name="question"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                />
              </InlineLabel>
            </FlexRow>

            <SubmitForm>
              <div>
                <Button
                  type="button"
                  color="#0076ff"
                  backgroundColor="transparent"
                  onClick={() => addNewAnswer({ formik })}
                >
                  Add New
                </Button>
              </div>
              <div>
                <Button color="white" type="submit">
                  Submit
                </Button>
              </div>
            </SubmitForm>
            {answers.map((answer, i) => {
              return (
                <CustomAnswer
                  key={i}
                  answer={answer}
                  handleChange={handleAnswerChange}
                  handleDelete={handleAnswerDelete}
                  index={i}
                />
              );
            })}
            <SubmitForm></SubmitForm>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
`;
const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    padding: 5px 10px;
    margin: 20px 0;
  }
`;

const SubmitForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 5px;
  margin: 10px 5px;
  width: 65%;
`;

const InputForm = styled.input`
  width: ${(props) => props.width || 500}px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
    color: #152935;
  }
`;
