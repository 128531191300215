import * as React from "react";
import styled from "styled-components";

export interface LoadingProps {
  color?: string;
  size?: number;
}

const NotFound: React.FunctionComponent<LoadingProps> = () => {
  return (
    <Div>
      <h1>404 Error</h1>
      <p>We can't seem to find the page you're looking for.</p>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { NotFound };
