import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React from "react";
import styled from "styled-components";
import { isLoggedInVar } from "../../apollo/cache";
import { LOGIN_USER } from "../../apollo/mutations";
import { theme } from "../../utils/theme";
import { Button } from "../UI";
import { Background } from "../UI/Background";

interface MyFormikProps {
  username: string;
  password: string;
}

export const Login = () => {
  const [loginMutation, { loading, error }] = useMutation(LOGIN_USER, {
    onCompleted({ adminLogin }) {
      if (!adminLogin) {
        return;
      }
      localStorage.setItem("token", adminLogin.session_token as string);
      localStorage.setItem("user_id", adminLogin.id as string);
      isLoggedInVar(true);
    },
    fetchPolicy: "no-cache",
    onError({ message }) {
      console.log("Error logging in: ", message);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      loginMutation({
        variables: { email: values.email, password: values.password },
      });
    },
  });

  const error_message = error ? "Invalid Username / Password" : "";

  if (loading) return <div>loading...</div>;
  return (
    <Background>
      <LoginBackGround>
        <Title>Grace Hypnosis</Title>
        <LoginForm onSubmit={formik.handleSubmit}>
          <AppErrorText>{error_message}</AppErrorText>
          <FlexCol>
            {formik.values.email ? <LabelRow>Email</LabelRow> : null}
            <LoginInput
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </FlexCol>
          <FlexCol>
            {formik.values.password ? <LabelRow>Password</LabelRow> : null}
            <LoginInput
              placeholder="Password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </FlexCol>

          <Button type="submit" color={"#FFF"} width={400}>
            Login
          </Button>
        </LoginForm>
      </LoginBackGround>
    </Background>
  );
};

const Title = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 37px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const LoginInput = styled.input`
  background: none;
  border: none;
  margin: 38px 10px;
  padding: 10px 5px;
  width: 345px;
  align-self: center;
  font-family: ${theme.PRIMARY_FONT};
  border-bottom: 1px solid ${theme.MEDIUM_GREY_COLOR};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &:focus {
    outline: none;
  }
`;

const LoginBackGround = styled.div`
  background: #fff;
  max-width: 960px;
  width: 90%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AppErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: -0.09px;
  text-align: center;
  color: ${theme.INPUT_BORDER_COLOR};
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: 34px;
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 30px;
  background: none;
  border: none;
  font-family: "Open Sans";
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
  margin-left: 12px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: auto;
`;
