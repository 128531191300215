import { useQuery } from "@apollo/client";
import { filter } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GET_ALL_ANSWERS,
  GET_ALL_CUSTOM_SESSIONS,
  GET_SESSIONS,
} from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { Body, BUTTON_END, Col, Head, Row } from "../../UI";
import { MultiSelect, SingleSelect } from "../../UI/Selector";
import { CustomSession } from "./CustomSession";

const mapped_object = (data, custom_data) => {
  return data.map((el, i) => {
    if (el.answer_ids) {
      if (el.answer_ids.toString() === custom_data.answer_ids.toString()) {
        return {
          ...custom_data,
        };
      } else {
        return el;
      }
    } else {
      return el;
    }
  });
};

export const CustomSessions = () => {
  const { loading, error, data } = useQuery(GET_ALL_CUSTOM_SESSIONS, {
    fetchPolicy: "network-only",
  });
  const [customSessions, setCustomSessions] = useState<any>([]);
  const [filtered, setFiltered] = useState<any>([]);

  const {
    data: session_data,
    loading: session_loading,
    error: session_error,
  } = useQuery(GET_SESSIONS, {
    fetchPolicy: "cache-first",
  });

  const {
    data: answer_data,
    loading: answer_loading,
    error: answer_error,
  } = useQuery(GET_ALL_ANSWERS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && customSessions.length === 0) {
      setCustomSessions(data.fetchCMSCustomSessions);
      setFiltered(data.fetchCMSCustomSessions);
    }
  }, [data]);

  if (loading || session_loading || answer_loading) return <p>loading ...</p>;
  if (error || answer_error) return `error! ${error}`;
  if (!data || !session_data || !answer_data)
    return <p>CustomSession Not found yet ...</p>;

  const handleParentData = (custom_data) => {
    const filtered_data = mapped_object(filtered, custom_data);
    const custom_sesssions = mapped_object(customSessions, custom_data);

    setFiltered(filtered_data);
    setCustomSessions(custom_sesssions);
  };

  let custom_data = filtered.map((custom_session, i) => {
    return (
      <CustomSession
        key={i}
        index={i}
        custom_session={custom_session}
        session_data={session_data}
        handleParentData={handleParentData}
      />
    );
  });

  const handleFilter = (filtered_list) => {
    if (filtered_list) {
      const values = filtered_list.map((el) => el.label);
      const filtered_data = customSessions.filter((custom_session: any) => {
        return (
          custom_session.type === "Header" ||
          values.every((val) => {
            return custom_session.row_values.includes(val);
          })
        );
      });
      setFiltered(filtered_data);
    } else {
      setFiltered(customSessions);
    }
  };
  return (
    <Container className="container">
      <FlexRow>
        <MultiBlock>
          <MultiSelect
            data={answer_data.customAnswers}
            handleChange={handleFilter}
          />
        </MultiBlock>
        <BUTTON_END to="custom/edit"> Manage Questions / Answers </BUTTON_END>
      </FlexRow>
      <Table>
        <Body>{custom_data}</Body>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 95%;
  margin: auto;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MultiBlock = styled.div`
  display: block;
  min-width: 700px;
`;
export const Table = styled.table`
  padding: 25px 15px;
  margin-top: 25px;
  align-self: center;
  text-align: center;
  display: grid;
  border-radius: 5px;
  background-color: #fff;
  border-collapse: unset;
  overflow: auto;
  grid-template-columns: repeat(6, 240px);
`;
