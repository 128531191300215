import styled from "styled-components";
import { theme } from "../../utils/theme";
import swal from "sweetalert2";
import * as React from "react";

export const Err = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: -0.05px;
  color: ${theme.INPUT_BORDER_COLOR};
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding-bottom: 10px;
`;

export const SwalError = (message) =>
  swal.fire({ title: "Error", text: message });

export const SuccessMessage = () =>
  swal.fire({
    icon: "success",
    title: "Saved!",
  });

export const SwalConfirmWarning = (message) =>
  swal
    .fire({
      title: "Warning",
      icon: "warning",
      text: message,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      focusCancel: true,
    })
    .then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        return true;
      } else if (result.isDismissed) {
        return false;
      }
    });

export const FieldMissingAlert = (fields) =>
  swal.fire({
    title: "Field Missing",
    icon: "error",
    text: fields,
  });

export const DeleteMessage = () =>
  swal.fire({
    icon: "info",
    title: "Deleted!",
  });

export interface Error {
  header?: string;
  body?: string;
}

const CustomizedErrorComponent: React.FunctionComponent<Error> = (props) => {
  return (
    <Div>
      <h1>{props.header ? props.header : "Error !"}</h1>
      <p>{props.body ? props.body : "Unknown Error occured!"}</p>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { CustomizedErrorComponent };
