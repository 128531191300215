import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { EDIT_ONE_SERIES } from "../../../apollo/mutations";
import { GET_SERIES } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { SeriesForm } from "./SeriesForm";

export const EditSeries = (props: any) => {
  const seriesId = Number(props.match.params.seriesId);

  const { data, loading, error } = useQuery(GET_SERIES, {
    variables: {
      series_id: seriesId,
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;

  return (
    <SeriesForm
      series={data?.fetchOneSeries}
      seriesId={seriesId}
      catMutation={EDIT_ONE_SERIES}
    />
  );
};
