import { useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GET_ALL_USERS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { CustomizedErrorComponent, Loading } from "../../UI";
import { User } from "./User";
import debounce from "lodash/debounce";

export const Users = () => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(GET_ALL_USERS, {
    onCompleted: ({ users }) => {
      console.log(users);
      setFilteredUsers(users);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (!data?.users) {
      setFilteredUsers([]);
      return;
    }
    const searchTextData = new RegExp(searchText, "i");
    const tempUsers = searchText
      ? data?.users.filter((el: any) => el.email.match(searchTextData))
      : data?.users;
    setFilteredUsers(tempUsers);
  }, [data?.users, searchText]);

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  });

  const debouncedChange = useMemo(
    () =>
      debounce((e) => {
        const { value } = e.target;
        setSearchText(value);
      }, 500),
    [],
  );

  if (loading) return <Loading />;
  if (error) return <CustomizedErrorComponent header="error getting users" />;

  return (
    <Container>
      <h1>Delete Users</h1>
      <FiltersContainer>
        <p>
          <b>Search: </b>
        </p>

        <Input
          type="text"
          name="searchText"
          // value={searchText}
          onChange={(e) => {
            e.persist();
            debouncedChange(e);
          }}
        />
      </FiltersContainer>
      <UsersContainer>
        {filteredUsers.map((user: any) => (
          <User data={user} key={user.id} />
        ))}
      </UsersContainer>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  width: 95%;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  background-color: white;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
`;

const Input = styled.input`
  width: ${(props) => props.width || 500}px;
  padding: 8px 5px;
  margin-left: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  background-color: #fff;
  font-size: 16px;
`;
