import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import * as Yup from "yup";
import { GET_ALL_PARENT, GET_SESSIONS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { AudioUpload } from "../../Segments/AudioUpload";
import { ImageUpload } from "../../Segments/ImageUpload";
import {
  BACK_BUTTON,
  Err,
  Loading,
  SAVE_BUTTON,
  SuccessMessage,
  TEXTAREA,
} from "../../UI";
import { DeleteSession } from "./DeleteSession";

const SessionValidationForm = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  image_url: Yup.string().required("Image is required"),
  is_active: Yup.boolean().required("Required"),
  is_premium: Yup.boolean().required("Required"),
  description: Yup.string(),
  length: Yup.number().required("length Required"),
  main_url: Yup.string().required("Audio File is required"),
});

export const SessionForm = ({
  session,
  sessionId,
  catMutation,
}: {
  session?: any;
  sessionId?: number | null | undefined;
  catMutation: any;
}) => {
  let history = useHistory();

  const [uploaded, setUploadStatus] = useState(false);

  // formik

  const [sessionMutation, { loading }] = useMutation(catMutation, {
    onCompleted(data) {
      SuccessMessage().then(() => {
        history.push(`/sessions`);
      });
    },
  });

  const { data: parent_data, loading: parent_loading } = useQuery(
    GET_ALL_PARENT,
    { fetchPolicy: "network-only" }
  );

  const handleSubmit = (values) => {
    let start_index = values.parent_id.indexOf("-") + 1;
    let id = values.parent_id.slice(start_index);
    id = Number(id);
    let series_id = null;
    let topic_id = null;
    let connect = {};

    if (values.parent_id.match(/series/i)) {
      series_id = id;
      connect = {
        Series: { connect: { id } },
      };
      if (sessionId && values.topic_id) {
        connect["Topic"] = { disconnect: true };
      }
    }
    if (values.parent_id.match(/topic/i)) {
      topic_id = id;
      connect = {
        Topic: { connect: { id } },
      };
      if (sessionId && values.series_id) {
        connect["Series"] = { disconnect: true };
      }
    }

    if (!sessionId) {
      sessionMutation({
        variables: {
          data: {
            title: values.title,
            image_url: values.image_url,
            is_active: values.is_active,
            is_premium: values.is_premium,
            description: values.description,
            bg_image_url: values.bg_image_url,
            length: values.length || 0,
            main_url: values.main_url,
            bg_url: values.main_url,
            ...connect,
          },
        },
      });
    } else {
      sessionMutation({
        variables: {
          data: {
            title: { set: values.title },
            image_url: { set: values.image_url || "" },
            is_active: { set: values.is_active },
            is_premium: { set: values.is_premium },
            bg_image_url: { set: values.bg_image_url },
            description: { set: values.description },
            length: { set: values.length || 0 },
            main_url: { set: values.main_url },
            bg_url: { set: values.main_url },
            ...connect,
          },
          where: { id: sessionId },
        },
      });
    }
  };

  const uploadText = uploaded ? "Replace Banner Image" : "Upload";

  if (loading) {
    return <Loading />;
  }

  const handleParentChange = ({ e, formik }: { e: any; formik: any }) => {
    console.log(e);

    const value = e.value;
    const label = e.label;

    formik.setFieldValue("parent_id", value);
    formik.setFieldValue("parent_label", label);
  };

  const handleCancel = () => {
    history.push(`/sessions`);
  };

  return (
    <Container>
      <BACK_BUTTON to="/topics">
        <FaChevronLeft fontSize={20} />
        <p>Back to Topics</p>
      </BACK_BUTTON>

      <Formik
        initialValues={{
          title: session ? session.title : "",
          is_active: session ? session.is_active : false,
          is_premium: session ? session.is_premium : false,
          description: session ? session.description : "",
          image_url: session ? session.image_url : "",
          bg_image_url: session ? session.bg_image_url : "",
          main_url: session ? session.main_url : "",
          series_id: session ? session.series_id : 0,
          topic_id: session ? session.topic_id : 0,
          bg_url: session ? session.bg_url : "",
          parent_id: session ? session.parent_id : 0,
          parent: session ? session.parent : "",
          parent_label: session ? session.parent_label : "",
          length: session ? session.length : 0,
        }}
        validationSchema={SessionValidationForm}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            <FlexRow>
              <InlineLabel>
                <p>Session Title</p>
                <InputForm
                  type="text"
                  placeholder="Session Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && formik.touched.title ? (
                  <Err>{formik.errors.title}</Err>
                ) : null}
              </InlineLabel>
              <InlineLabel>
                <p>Status </p>
                <Selector
                  className="selector"
                  name="is_active"
                  class
                  value={{
                    value: formik.values.is_active,
                    label: formik.values.is_active ? "Active" : "Not Active",
                  }}
                  onChange={(e) => formik.setFieldValue("is_active", e?.value)}
                  options={[
                    { value: true, label: "Active" },
                    {
                      value: false,
                      label: "Not Active",
                    },
                  ]}
                />
              </InlineLabel>
              <InlineLabel>
                <p> Free/Premium </p>
                <Selector
                  className="selector"
                  name="is_premium"
                  class
                  value={{
                    value: formik.values.is_premium,
                    label: formik.values.is_premium ? "Premium" : "Free",
                  }}
                  onChange={(e) => formik.setFieldValue("is_premium", e?.value)}
                  options={[
                    { value: true, label: "Premium" },
                    {
                      value: false,
                      label: "Free",
                    },
                  ]}
                />
              </InlineLabel>
              <InlineLabel>
                <p>Parent </p>

                <Selector
                  fieldname="parent_id"
                  value={{
                    value: formik.values.parent_id,
                    label: formik.values.parent_label || "None",
                  }}
                  onChange={(e) => handleParentChange({ e: e, formik: formik })}
                  options={
                    !!parent_data
                      ? parent_data.fetchAllParent.map((el) => {
                          return {
                            label: `${el.data_type} - ${el.title}`,
                            value: `${el.data_type}-${el.id}`,
                          };
                        })
                      : []
                  }
                  name="SessionParent"
                />
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                <p>Session Description </p>
                <TEXTAREA
                  width={600}
                  margin={[20, 0]}
                  padding={[8, 0]}
                  placeholder="Session Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />

                {formik.errors.description && formik.touched.description ? (
                  <Err>{formik.errors.description}</Err>
                ) : null}
              </InlineLabel>
            </FlexRow>
            <FlexRow>
              <InlineLabel>
                {formik.errors.image_url && formik.touched.image_url ? (
                  <Err>{formik.errors.image_url}</Err>
                ) : null}
                <ImageUpload formik={formik} />
              </InlineLabel>
              <InlineLabel>
                {formik.errors.bg_image_url && formik.touched.bg_image_url ? (
                  <Err>{formik.errors.bg_image_url}</Err>
                ) : null}
                <ImageUpload
                  formik={formik}
                  name="bg_image_url"
                  header="Background Image"
                />
              </InlineLabel>
              <InlineLabel>
                {formik.errors.main_url && formik.touched.main_url ? (
                  <Err>{formik.errors.main_url}</Err>
                ) : null}
                <AudioUpload formik={formik} />
              </InlineLabel>
            </FlexRow>

            <LastSection>
              <SAVE_BUTTON type="submit">Save</SAVE_BUTTON>
              <SAVE_BUTTON
                type="button"
                background={theme.CANCEL_COLOR}
                onClick={handleCancel}
              >
                Cancel
              </SAVE_BUTTON>
              {sessionId ? <DeleteSession sessionId={sessionId} /> : null}
            </LastSection>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
}

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  max-width: 1200px;
  width: 95%;
  margin: auto;
  background-color: #fff;
`;

const Selector = styled(Select).attrs({ activeClassName: "selector" })`
  width: 200px;
`;

const InputForm = styled.input`
  width: 259px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 5px;
`;

const InlineLabel = styled.div`
  display: inline-block;
  margin-top: 13px;
  margin-right: 20px;
  p {
    font-size: 14px;
    margin: 0 0 7px;
  }
`;

const LastSection = styled.section`
  margin: auto;
  grid-row: 3;
  text-align: center;
`;

const FlexRow = styled.div`
  display: flex;
  margin-left: 20px;
  & > * {
    margin-left: 26px;
  }
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;
